import React, { useState, useContext } from 'react';
import AppSidebar from '../AppSidebar';
import DetailFound from '../Detail/DetailFound';

import {
  CButton,
} from '@coreui/react'

function Detail({ profile, setProfile }) {
//   const [showLogo, setShowLogo] = useState(localStorage.getItem('showLogo') === 'true');
  // const [profile, setProfile] = useState(localStorage.getItem('name') || ''); 

  return (
      <div>
        <AppSidebar setProfile={setProfile} />
        <div className="wrapper d-flex flex-column min-vh-100">
          {/* <Header profile={profile} />  */}
          <div className="body flex-grow-1">
            <DetailFound profile={profile} setProfile={setProfile} /> 
          </div>
        </div>
      </div>
  );
}

export default Detail;