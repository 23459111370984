import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../../scss/style.scss'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Importar estilos

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CCollapse,
  CToast,
  CToastBody,
  CToastClose,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilArrowLeft,
  cilCalendar,
} from '@coreui/icons'

import iconCheck from '../../assets/images/check.png'
import iconArrowUp from '../../assets/images/up-arrowhead-in-a-circle.png'
import iconArrowDown from '../../assets/images/down-arrowhead-in-a-circle.png'
import iconImagenUpload from '../../assets/images/icon-imagen-upload.png'
import trash from '../../assets/images/trash.png'

function CustomInput({ value, onChange, onFocus }) {
  const inputRef = useRef(null);

  return (
    <div className="input-group">
      <CFormInput
        className="letra-contenido-modal-input"
        size="sm"
        placeholder="Seleccione fecha"
        value={value}
        readOnly
        ref={inputRef}
      //onClick={() => onFocus(inputRef.current)} // Abre el DatePicker al hacer clic en el input
      />
      <div className="input-group-append">
        <span className="input-group-text" onClick={() => onFocus(inputRef.current)}>
          <CIcon
            icon={cilCalendar}
            size="lg"
            className="height-auto"
            style={{ "--ci-primary-color": "black" }}
          />
        </span>
      </div>
    </div>
  );
}

function FormVideo({ profile, setProfile }) {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null); // Inicializar con la fecha 
  const [selectedDate2, setSelectedDate2] = useState(null); // Inicializar con la fecha2
  const [selectedDate3, setSelectedDate3] = useState(null); // Inicializar con la fecha2
  const [idProyecto, setidProyecto] = useState(null);
  const [idCarreteras, setIdCarreteras] = useState(null);
  const [nombreProyecto1, setNombreProyecto1] = useState(null);
  const [cliente1, setCliente1] = useState(null);
  const [operador1, setOperador1] = useState(null);
  const [descripcion1, setDescripcion1] = useState(null);
  const [formularios, setFormularios] = useState([]);
  const [carreteraIndex, setCarreteraIndex] = useState(0); // Nuevo estado para guardar el 
  const [showSegmentos, setShowSegmentos] = useState([]);
  const [segmentoVisible, setSegmentoVisible] = useState([]); // Nuevo estado para la visibilidad de cada segmento
  const datePickerRef = useRef(null); // Referencia para el DatePicker
  const datePickerRef2 = useRef(null); // Referencia para el DatePicker
  const datePickerRef3 = useRef(null); // Referencia para el DatePicker
  const [registro, setRegistro] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataProyecto, setDataProyecto] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [collapseVisible, setCollapseVisible] = useState(false);

  const [carreteras, setCarreteras] = useState([
    {
      id: Date.now(),
      nombre: '',
      tipo: '',
      cantCarriles: '',
      concesion: '',
      segmentos: [],
      isNew: 1
    }
  ]);

  const [formularioCarretera, setFormularioCarretera] = useState({
    id: Date.now(),
    nombre: '',
    tipo: '',
    cantCarriles: '',
    concesion: '',
    segmentos: [],
    isNew: 1,
  });

  const [errors, setErrors] = useState({
    nombreProyecto1: '',
    selectedDate: '',
    selectedDate2: '',
    cliente1: '',
    operador1: '',
    // ... Errores para los campos de carretera
  });

  // Función para validar los campos
  const validarFormulario = () => {
    let newErrors = {};

    // Validar campos de Proyecto
    if (!nombreProyecto1) {
      newErrors.nombreProyecto1 = 'Este campo es obligatorio';
    }
    if (!selectedDate) {
      newErrors.selectedDate = 'Este campo es obligatorio';
    }
    if (!selectedDate2) {
      newErrors.selectedDate2 = 'Este campo es obligatorio';
    }
    if (!cliente1) {
      newErrors.cliente1 = 'Este campo es obligatorio';
    }
    if (!operador1) {
      newErrors.operador1 = 'Este campo es obligatorio';
    }

    // Validar campos de cada segmento
    carreteras.forEach((carretera, carreteraIndex) => {
      if (!carretera.nombre) { // Validar el campo nombre de la carretera
        newErrors[`nombreCarreteraError${carreteraIndex}`] = 'Este campo es obligatorio';
      }

      carretera.segmentos.forEach((segmento, segmentoIndex) => {
        if (
          // !segmento.kminicio || 
          // !segmento.kmfin || 
          // !segmento.longitudKm1 || 
          // !segmento.tipoPavimento ||
          !segmento.fechaRelevamiento  // Verifica si la fecha de relevamiento está vacía
          // !segmento.tipoBanquina ||
          // !segmento.estadoBanquina ||
          // !segmento.anchoInterno ||
          // !segmento.anchoExterno ||
          // !segmento.numCarriles ||
          // !segmento.sentidoCarril ||
          // !segmento.tipoSeparador ||
          // !segmento.anchoCalzada ||
          // !segmento.estadoDrenaje ||
          // !segmento.estadoBordeCalzada
        ) {
          // Agrega un error para el segmento específico
          newErrors[`segmentoError${carreteraIndex}-${segmentoIndex}`] = 'Campo obligatorio'; // 
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

   // Función para manejar el cambio de fecha en el DatePicker del segmento
   const handleFechaRelevamientoChange = (date, carreteraIndex, segmentoIndex) => {
    setCarreteras(prevCarreteras => prevCarreteras.map((c, i) =>
      i === carreteraIndex ? {
        ...c,
        segmentos: c.segmentos.map((s, j) =>
          j === segmentoIndex ? { ...s, fechaRelevamiento: date } : s
        )
      } : c
    ));

    // Eliminar el error si se selecciona una fecha
    if (date) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [`segmentoError${carreteraIndex}-${segmentoIndex}`]: undefined 
      }));
    }
  };

  const handleGoBack = () => {
    navigate(-1); // Navega a la página anterior
  };

  const handleFocus = (element) => {
    if (datePickerRef.current) {
      datePickerRef.current.focus(); // Ajusta el foco para abrir el DatePicker
    }
  };
  const handleFocus2 = (element) => {
    if (datePickerRef2.current) {
      datePickerRef2.current.focus(); // Ajusta el foco para abrir el DatePicker
    }
  };

  const handleFocus3 = (element) => {
    if (datePickerRef3.current) {
      datePickerRef3.current.focus(); // Ajusta el foco para abrir el DatePicker
    }
  };

  const handleCloseToast = (element) => {
    setShowSuccessToast(false);
  }
  const handleCloseToastError = (element) => {
    setShowErrorToast(false);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    // ...

    // Asegúrate de que 'carreteras' se define aquí
    setShowSegmentos(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => false)));
    setSegmentoVisible(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => true)));

    // ...
  }, [carreteras]);

  // Array de arrays para controlar la visibilidad de cada segmento
  const [segmentoVisibilidad, setSegmentoVisibilidad] = useState(() => {
    return carreteras.map(c => [...new Array(c.segmentos.length)].map(() => false)); // Array vacío para cada segmento
  });

  useEffect(() => {
    setFormularios([
      {
        id: Date.now(), // ID único para cada formulario
        videos: [], // Array para los videos del formulario
        kminicio: '',
        kmfin: '',
        longitudKm1: '',
        tipoPavimento: '',
        fechaRelevamiento: '',
        tipoBanquina: '',
        estadoBanquina: '',
        anchoInterno: '',
        anchoExterno: '',
        numCarriles: '',
        sentidoCarril: '',
        tipoSeparador: '',
        anchoCalzada: '',
        estadoDrenaje: '',
        estadoBordeCalzada: '',
      }
    ]);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Muestra un indicador de carga mientras se realiza la solicitud

      try {
        const tokenData = localStorage.getItem('token'); // Obtén el token desde el localStorage
        const token = tokenData ? JSON.parse(tokenData).token : null; // Parsear el token JSON

        if (!token) {
          throw new Error('Token no disponible en localStorage'); // Manejar el caso en el que no haya token
        }

        // Realiza la solicitud a la API con el token en los headers
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/edit/project/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        // Si la respuesta no es correcta, lanza un error
        if (!response.ok) {
          if (response.status === 401) { 
            // Token inválido o expirado
            console.error("Error 401: No autorizado");
            // Redirige al inicio de sesión, limpia el token, etc.
            localStorage.removeItem('token');
            window.location.href = '/login'; // Ajusta la ruta si es necesario 
          } else {
            // Otros errores
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }
        }

        // Obtén los datos JSON de la respuesta
        const jsonData = await response.json();
        // console.log('Datos JSON recibidos:', jsonData); // Imprime los datos recibidos

        // Actualiza el estado de dataProyecto con los datos recibidos
        setDataProyecto(jsonData);

        // Guarda los datos en el localStorage si es necesario
        localStorage.setItem('data-proyecto', JSON.stringify(jsonData));

      } catch (error) {
        console.error('Error durante la solicitud:', error); // Manejo de errores
        setError(error); // Guarda el error en el estado para mostrarlo en la interfaz
      } finally {
        setIsLoading(false); // Detén el indicador de carga
      }
    };

    fetchData(); // Llama a la función fetchData cuando el componente se monte o cambie el id

    // Este useEffect se ejecutará siempre que cambie `id` y asegurará que los datos se recarguen
  }, [id]);

  useEffect(() => {
    if (dataProyecto ) {
      if (dataProyecto.proyecto) {
      // Carga información del proyecto
      setidProyecto(dataProyecto.proyecto[0].id);
      setNombreProyecto1(dataProyecto.proyecto[0].nombre);
      setCliente1(dataProyecto.proyecto[0].cliente)
      setOperador1(dataProyecto.proyecto[0].operador)
      setDescripcion1(dataProyecto.proyecto[0].descripcion)
      setSelectedDate(new Date(dataProyecto.proyecto[0].fecha_inicio));
      setSelectedDate2(new Date(dataProyecto.proyecto[0].fecha_fin));
      }

      // Carga información de las carreteras
      if (dataProyecto.carretera && dataProyecto.carretera.length > 0) {
        setCarreteras(dataProyecto.carretera.map((c, index) => {
          const segmentos = c.segmentos.map((s) => ({ // Convierte las fechas aquí
            ...s,
            fechaRelevamiento: new Date(s.fechaRelevamiento) // Convierte la cadena a un objeto Date
          }));
          const carreteraData = {
            id: c.id,
            nombre: c.nombre,
            tipo: c.tipo,
            cantCarriles: c.cant_carriles,
            concesion: c.concesion,
            isNew: c.isNew,
            segmentos: segmentos,
          };
          // console.log(carreteraData)
          return carreteraData;
        }));

        // Ajusta el estado de segmentoVisibilidad y showSegmentos
        setSegmentoVisible(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => true)));
        setShowSegmentos(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => false)));

        // Inicializa carreteraIndex y segmentoVisibilidad
        setCarreteraIndex(dataProyecto.carretera.length);
        setSegmentoVisibilidad(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => false))); // Inicializa con un array de arrays vacío
      } else {
        setCarreteras([]);
        setSegmentoVisible([]); // Inicializa segmentoVisible con un array vacío
        setShowSegmentos([]);  // Inicializa showSegmentos con un array vacío
      }
    }
    // console.log(dataProyecto)
    // console.log(carreteras[0])
    // console.log(carreteras[1])
  }, [dataProyecto]);

  useEffect(() => {
    // setShowSegmentos(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => false)));
    setSegmentoVisible(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => true)));
    // setSegmentoVisibilidad(carreteras.map(c => [...new Array(c.segmentos.length)].map(() => false))); // Array vacío para cada segmento
  }, [carreteras]);

  useEffect(() => {
    if (carreteraIndex == 0) {
      setCarreteraIndex(1);
    }
    // console.log('carretraIndex', carreteraIndex)
  }, [carreteraIndex]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validarFormulario()) { // Validar antes de envia
    setIsLoading(true);

    // Convierte las fechas a formato ISO
    const formatDate = (date) => date?.toISOString().slice(0, 19).replace('T', ' ');
    let fechaInicio1 = formatDate(selectedDate);
    let fechaFin1 = formatDate(selectedDate2);
    let fechaRelevamiento = formatDate(selectedDate3);

    // Crea un objeto de proyecto con la estructura adecuada
    const proyecto = {
      idProyecto,
      nombreProyecto1,
      fechaInicio1,
      fechaFin1,
      cliente1,
      operador1,
      descripcion1,
      carreteras: []
    };

    // Agrega las carreteras al proyecto
    carreteras.forEach((carretera) => {
      const carreteraData = {
        id: carretera.id,
        nombre: carretera.nombre,
        tipo: carretera.tipo,
        cantCarriles: carretera.cantCarriles,
        concesion: carretera.concesion,
        isNew: carretera.isNew, // Agrega el campo isNew
        segmentos: [],

      };

      // Agrega los segmentos a la carretera
      carretera.segmentos.forEach((segmento) => {
        carreteraData.segmentos.push({
          id: segmento.id,
          kminicio: segmento.kminicio,
          kmfin: segmento.kmfin,
          longitudKm1: segmento.longitudKm1,
          tipoPavimento: segmento.tipoPavimento,
          fechaRelevamiento: formatDate(segmento.fechaRelevamiento), // Convierte la fecha al formato ISO
          tipoBanquina: segmento.tipoBanquina,
          estadoBanquina: segmento.estadoBanquina,
          anchoInterno: segmento.anchoInterno,
          anchoExterno: segmento.anchoExterno,
          numCarriles: segmento.numCarriles,
          sentidoCarril: segmento.sentidoCarril,
          tipoSeparador: segmento.tipoSeparador,
          anchoCalzada: segmento.anchoCalzada,
          estadoDrenaje: segmento.estadoDrenaje,
          estadoBordeCalzada: segmento.estadoBordeCalzada,
          isNew: segmento.isNew // Agrega el campo isNew
        });
      });
      proyecto.carreteras.push(carreteraData);
    });

    try {
      const tokenData = localStorage.getItem('token');
      const token = tokenData ? JSON.parse(tokenData).token : null;
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/update/project`, proyecto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json', // Ahora envía JSON
          'Authorization': 'Bearer ' + token
        }
      });

      // ... (Resto del manejo de la respuesta)
      setShowSuccessToast(true);
    } catch (error) {
      if (error.response.status === 401) { 
        // Token inválido o expirado
        console.error("Error 401: No autorizado");
        // Redirige al inicio de sesión, limpia el token, etc.
        localStorage.removeItem('token');
        window.location.href = '/login'; // Ajusta la ruta si es necesario 
        console.error('Error al subir el video:', error);
      }
      setShowErrorToast(true)
    } finally {
      setIsLoading(false); // Ocultar el spinner al finalizar la carga
    }
  }
}

  // Función auxiliar para agregar un segmento
  const agregarSegmento = (index) => {
    setCarreteras(prevCarreteras => prevCarreteras.map((c, i) =>
      i === index ? {
        ...c,
        segmentos: [...c.segmentos, {
          id: Date.now(),
          kminicio: '',
          kmfin: '',
          longitudKm1: '',
          tipoPavimento: '',
          fechaRelevamiento: null,
          tipoBanquina: '',
          estadoBanquina: '',
          anchoInterno: '',
          anchoExterno: '',
          numCarriles: '',
          sentidoCarril: '',
          tipoSeparador: '',
          anchoCalzada: '',
          estadoDrenaje: '',
          estadoBordeCalzada: '',
          visible: false,
          isNew: 1 // Indica que este registro es nuevo
        }]
      } : c
    ));

    setSegmentoVisibilidad(prevSegmentoVisibilidad => {
      // Asegúrate de que prevSegmentoVisibilidad tenga la longitud correcta
      if (prevSegmentoVisibilidad.length <= index) {
        prevSegmentoVisibilidad = [...prevSegmentoVisibilidad, ...new Array(index - prevSegmentoVisibilidad.length + 1).fill([])];
      }
    
      // Ahora puedes acceder a prevSegmentoVisibilidad[index] y usar concat
      return [
        ...prevSegmentoVisibilidad.slice(0, index),
        prevSegmentoVisibilidad[index].concat(false), // Agrega 'false' al array del segmento
        ...prevSegmentoVisibilidad.slice(index + 1)
      ];
    });
  }

  // Función auxiliar para agregar un segmento
  const agregarSegmentoACarretera = () => {
    setCarreteras(prevCarreteras => prevCarreteras.map((c, i) =>
      i === carreteraIndex ? {
        ...c,
        segmentos: [...c.segmentos, {
          id: Date.now(),
          kminicio: '',
          kmfin: '',
          longitudKm1: '',
          tipoPavimento: '',
          fechaRelevamiento: '',
          tipoBanquina: '',
          estadoBanquina: '',
          anchoInterno: '',
          anchoExterno: '',
          numCarriles: '',
          sentidoCarril: '',
          tipoSeparador: '',
          anchoCalzada: '',
          estadoDrenaje: '',
          estadoBordeCalzada: '',
          visible: false // Inicialmente oculto
        }]
      } : c
    ));
  };

  // Función para agregar una carretera
  const agregarCarretera = () => {
    // Actualiza el estado de "carreteras"
    setCarreteras(prevCarreteras => [...prevCarreteras, {
      id: Date.now(),
      nombre: formularioCarretera.nombre,
      tipo: formularioCarretera.tipo,
      cantCarriles: formularioCarretera.cantCarriles,
      concesion: formularioCarretera.concesion,
      isNew: 1, // Indica que este registro es nuevo
      segmentos: [],
    }]);

    // Actualiza el estado de "carreteraIndex"
    setCarreteraIndex(prevCarreteraIndex => prevCarreteraIndex + 1);

    // Restablece el formulario de carretera
    setFormularioCarretera({
      id: Date.now(),
      nombre: '',
      tipo: '',
      cantCarriles: '',
      concesion: '',
      segmentos: [],
      isNew: 1,
    });
  };

  const toggleSegmentoVisibilidad = (carreteraIndex, segmentoIndex) => {
    // console.log("carreteraIndex:", carreteraIndex);
    // console.log("segmentoIndex:", segmentoIndex);
  
    setSegmentoVisibilidad(prevSegmentoVisibilidad => {
      // console.log("segmentoVisibilidad antes del cambio:", prevSegmentoVisibilidad); 
  
      const newSegmentoVisibilidad = JSON.parse(JSON.stringify(prevSegmentoVisibilidad)); 
      newSegmentoVisibilidad[carreteraIndex][segmentoIndex] = !newSegmentoVisibilidad[carreteraIndex][segmentoIndex];
  
      // console.log("segmentoVisibilidad después del cambio:", newSegmentoVisibilidad); 
  
      // console.log("Llamada a setSegmentoVisibilidad"); 
  
      // Imprime el valor de segmentoVisibilidad después de setSegmentoVisibilidad
      // console.log("segmentoVisibilidad después de la actualización del estado:", segmentoVisibilidad); 
  
      return newSegmentoVisibilidad; // Actualiza el estado
    });
  };

  // Función para eliminar el último segmento de la carretera
  const eliminarUltimoSegmento = (carreteraIndex) => {
    // Verifica si el segmento es nuevo (isNew === 1) y si es el último de la lista
    if (carreteras[carreteraIndex].segmentos.length > 0 && carreteras[carreteraIndex].segmentos[carreteras[carreteraIndex].segmentos.length - 1].isNew === 1) {
      // Elimina el último segmento del array
      setCarreteras(prevCarreteras => prevCarreteras.map((c, i) =>
        i === carreteraIndex ? {
          ...c,
          segmentos: c.segmentos.slice(0, -1) // Elimina el último elemento
        } : c
      ));

      // Actualiza el estado de segmentoVisibilidad para remover la entrada del último segmento
      setSegmentoVisibilidad(prevSegmentoVisibilidad =>
        prevSegmentoVisibilidad.map((c, i) =>
          i === carreteraIndex ?
            c.slice(0, -1) : // Elimina el último elemento
            c
        )
      );
    }
  };

  // Función para eliminar la última carretera 
  const eliminarUltimaCarretera = () => {
    // Verifica si la última carretera es nueva (isNew === 1) y si hay al menos una carretera
    if (carreteras.length > 0 && carreteras[carreteras.length - 1].isNew === 1) {
      // Elimina la última carretera del array
      setCarreteras(prevCarreteras => prevCarreteras.slice(0, -1));

      // Actualiza carreteraIndex si es necesario
      setCarreteraIndex(prevCarreteraIndex => prevCarreteraIndex - 1);
    }
  };

  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs>
          <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
            <CIcon onClick={handleGoBack} icon={cilArrowLeft} size="xxl" className="height-auto icon-background-orange cursor-pointer" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Editar Proyecto</strong></h5>
          </CCol>
          <CCol xs={10} style={{ 'height': '29rem' }}>
            <CCard className="mb-4 scroll-contenedor-new-proyect" style={{ 'height': '500px' }}>
              <CCol xs={10} className='titulo-formulario-new-proyect'>Proyecto ID #</CCol>
              <CCardBody>
                <CForm onSubmit={handleSubmit}>
                  <div className="mb-3 letra-contenido-2">
                    <CRow>
                      <CCol xs={10}>
                        <CFormLabel htmlFor="nombreProyecto1">Nombre de proyecto</CFormLabel>
                        <CFormInput className='letra-contenido-modal-input' size="sm" id="nombreProyecto1" placeholder="Ingrese nombre" value={nombreProyecto1} onChange={(e) => {
                          // Actualiza el estado del formulario

                          setNombreProyecto1(e.target.value);

                          // Elimina el error si se corrige el campo
                          if (e.target.value) {
                            setErrors(prevErrors => ({
                              ...prevErrors,
                              [`nombreProyecto1`]: undefined
                            }));
                          }
                        }} />
                        {errors.nombreProyecto1 && <div className="text-danger">{errors.nombreProyecto1}</div>} {/* Mostrar error */}
                      </CCol>
                    </CRow>
                  </div>

                  <div className="mb-3 letra-contenido-2">
                    <CRow>
                      <CCol xs={5}>
                        <div>
                          <div className="datePicker-container"> {/* Contenedor para label y DatePicker */}
                            <CFormLabel htmlFor="fecha-inicio">Fecha de ejecución inicio</CFormLabel>
                            <DatePicker
                              ref={datePickerRef}
                              id="fecha-inicio"
                              selected={selectedDate}
                              onChange={(date) => { // Obtén la fecha directamente del argumento
                                setSelectedDate(date);
                      
                                // Elimina el error si se selecciona una fecha
                                if (date) {
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    selectedDate: undefined // Corrige la clave aquí
                                  }));
                                } else { 
                                  // Puedes agregar aquí la lógica para mostrar un error 
                                  // si el usuario elimina la fecha seleccionada, si es necesario
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    selectedDate: "Este campo es requerido" 
                                  }));
                                }
                              }}
                              customInput={
                                <CustomInput
                                  value={selectedDate ? selectedDate.toLocaleDateString() : ""}
                                  onChange={setSelectedDate}
                                  onFocus={handleFocus}
                                />
                              }
                            />
                            {errors.selectedDate && <div className="text-danger">{errors.selectedDate}</div>} {/* Mostrar error */}
                          </div>
                        </div>
                      </CCol>
                      <CCol xs={5}>
                        <div>
                          <div className="datePicker-container"> {/* Contenedor para label y DatePicker */}
                            <CFormLabel htmlFor="fecha-inicio">Fecha de ejecución fin</CFormLabel>
                            <DatePicker
                              ref={datePickerRef2}
                              id="fecha-inicio"
                              selected={selectedDate2}
                              onChange={(date) => { // Obtén la fecha directamente del argumento
                                setSelectedDate2(date);

                                // Elimina el error si se selecciona una fecha
                                if (date) {
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    selectedDate2: undefined // Corrige la clave aquí
                                  }));
                                } else {
                                  // Puedes agregar aquí la lógica para mostrar un error 
                                  // si el usuario elimina la fecha seleccionada, si es necesario
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    selectedDate2: "Este campo es requerido"
                                  }));
                                }
                              }}
                              customInput={
                                <CustomInput
                                  value={selectedDate2 ? selectedDate2.toLocaleDateString() : ""}
                                  onChange={setSelectedDate2}
                                  onFocus={handleFocus2}
                                />
                              }
                            />
                            {errors.selectedDate2 && <div className="text-danger">{errors.selectedDate2}</div>} {/* Mostrar error */}
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </div>

                  <div className="mb-3 letra-contenido-2">
                    <CRow>
                      <CCol xs={5}>
                        <CFormLabel htmlFor="cliente1">Cliente</CFormLabel>
                        <CFormInput className='letra-contenido-modal-input' size="sm" id="cliente1" placeholder="Ingrese" value={cliente1} onChange={(e) => {
                          // Actualiza el estado del formulario

                          setCliente1(e.target.value);

                          // Elimina el error si se corrige el campo
                          if (e.target.value) {
                            setErrors(prevErrors => ({
                              ...prevErrors,
                              [`cliente1`]: undefined
                            }));
                          }
                        }} />
                        {errors.cliente1 && <div className="text-danger">{errors.cliente1}</div>} {/* Mostrar error */}
                      </CCol>

                      <CCol xs={5}>
                        <CFormLabel htmlFor="operador1">Operador</CFormLabel>
                        <CFormInput className='letra-contenido-modal-input' size="sm" id="operador1" placeholder="Ingrese" value={operador1} onChange={(e) => {
                          // Actualiza el estado del formulario

                          setOperador1(e.target.value);

                          // Elimina el error si se corrige el campo
                          if (e.target.value) {
                            setErrors(prevErrors => ({
                              ...prevErrors,
                              [`operador1`]: undefined
                            }));
                          }
                        }} />
                        {errors.operador1 && <div className="text-danger">{errors.operador1}</div>} {/* Mostrar error */}
                      </CCol>
                    </CRow>
                  </div>

                  <div className="mb-3 letra-contenido-2">
                    <CRow>
                      <CCol xs={10}>
                        <CFormLabel htmlFor="descripcion1">Descripción</CFormLabel>
                        <CFormTextarea
                          className='letra-contenido-modal-input'
                          id="exampleFormControlTextarea1"
                          placeholder="Escribe comentarios u observaciones"
                          value={descripcion1}
                          onChange={(e) => setDescripcion1(e.target.value)}
                          rows={4}
                        ></CFormTextarea>
                      </CCol>
                    </CRow>
                  </div>
                  <hr className='width-hr-form-new-proyect'></hr>
                  {carreteras.map((carretera, carreteraIndex) => (
                    <div key={carretera.id}>
                      {/* Mostrar la información de la carretera */}
                      <CRow>
                        <CCol xs={10}>
                          <CRow className='align-items-center justify-content-between'> {/* Alinear elementos verticalmente y horizontalmente */}
                            <CCol xs={8} className='titulo-formulario-new-carretera'>
                              Carretera #{carreteraIndex + 1}
                            </CCol>
                            <CCol xs={1} className="justify-content-end"> {/* Alinea el botón a la derecha */}
                              {carreteraIndex === carreteras.length - 1 && carretera.isNew === 1 && (
                                <CButton
                                  className="btn-delete-orange"
                                  onClick={eliminarUltimaCarretera}
                                >
                                  <img src={trash} alt="Selected Image" className="icon-arrow-collapse" />
                                </CButton>
                              )}
                            </CCol>
                          </CRow>
                        </CCol>
                      </CRow>

                      {/* Formulario de Carretera */}
                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={10} className='letra-contenido-2'>
                            <CFormInput className='letra-contenido-modal-input' size="sm" id="idCarretera" placeholder="Ingrese nombre" value={carretera.id} hidden onChange={(e) => setIdCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? { ...c, id: e.target.value } : c))} />
                          </CCol>
                        </CRow>
                      </div>
                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={10} className='letra-contenido-2'>
                            <CFormLabel htmlFor="nombreCarretera">Nombre de carretera/ruta</CFormLabel>
                            <CFormInput className='letra-contenido-modal-input' size="sm" id="nombreCarretera" placeholder="Ingrese nombre" value={carretera.nombre} onChange={(e) => {
                    // Actualiza el estado del formulario
                    setCarreteras(prevCarreteras => 
                      prevCarreteras.map((c, i) => 
                        i === carreteraIndex ? { ...c, nombre: e.target.value } : c
                      )
                    );

                    // Elimina el error si se ingresa un valor
                    if (e.target.value) {
                      setErrors(prevErrors => ({
                        ...prevErrors,
                        [`nombreCarreteraError${carreteraIndex}`]: undefined
                      }));
                    }
                  }} 
                />

                            {errors[`nombreCarreteraError${carreteraIndex}`] && (
                              <div className="text-danger">{errors[`nombreCarreteraError${carreteraIndex}`]}</div>
                            )}
                          </CCol>
                        </CRow>
                      </div>
                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={4}>
                            <CFormSelect size="sm"
                              className='letra-contenido-modal-input'
                              style={{ width: '100%' }}
                              label="Tipo de carretera"
                              value={carretera.tipo}
                              onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? { ...c, tipo: e.target.value } : c))}>
                              <option className='fuente-letra-oscura' >Seleccione tipo</option>
                              <option className='fuente-letra-oscura' value="autopista">Autopista</option>
                              <option className='fuente-letra-oscura' value="autovia">Autovia</option>
                              <option className='fuente-letra-oscura' value="carretera">Carretera</option>
                            </CFormSelect>
                          </CCol>
                          <CCol xs={3}>
                            <CFormSelect size="sm"
                              className='letra-contenido-modal-input'
                              label="Cantidad de carriles"
                              value={carretera.cantCarriles}
                              onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? { ...c, cantCarriles: e.target.value } : c))}>
                              <option className='fuente-letra-oscura' >Seleccione</option>
                              <option className='fuente-letra-oscura' value="1">1</option>
                              <option className='fuente-letra-oscura' value="2">2</option>
                              <option className='fuente-letra-oscura' value="3">3</option>
                              <option className='fuente-letra-oscura' value="4">4</option>
                            </CFormSelect>
                          </CCol>
                          <CCol xs={3}>
                            <CFormSelect size="sm"
                              className='letra-contenido-modal-input'
                              label="Concesión"
                              value={carretera.concesion}
                              onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? { ...c, concesion: e.target.value } : c))}>
                              <option className='fuente-letra-oscura' >Seleccione</option>
                              <option className='fuente-letra-oscura' value="si">Si</option>
                              <option className='fuente-letra-oscura' value="no">No</option>
                            </CFormSelect>
                          </CCol>
                        </CRow>
                      </div>

                      {/* Mostrar los segmentos del formulario de carretera */}
                      {carreteras[carreteraIndex].segmentos &&
                        carreteras[carreteraIndex].segmentos.map((segmento, index) => (
                          <div key={segmento.id}>
                            <div className="">
                              <CRow >
                                <CCol xs={10}>
                                  <CCard className="delete-border-color">
                                    <CRow className='delete-border-color align-items-center justify-content-between'>
                                      <CCol xs={9} className='titulo-formulario-new-segmento segmento-form-padding texto-desplazado'><div className='display-flex-icon'> <span className="texto-desplazado">Segmento #{index + 1}</span>
                                      <span>
                                    {/* Botón de eliminar el último segmento */}
                                    {index === carreteras[carreteraIndex].segmentos.length - 1 && (
                                          <CButton
                                            className="btn-delete-orange"
                                            onClick={() => eliminarUltimoSegmento(carreteraIndex)} // Pasa el índice de la carretera
                                            index={index} // Pasa el índice del segmento
                                            segmento={segmento} // Pasa el objeto segmento
                                          >
                                            <img src={trash} alt="Selected Image" className="icon-trash-collapse" />
                                            {/* <CIcon icon={cilTrash} size="sm" className="height-auto icon-background-orange" style={{ '--ci-primary-color': 'white' }} /> */}
                                          </CButton>
                                        )}
                                       </span>
                                       </div>
                                      </CCol>
                                      <CCol xs={1}></CCol>
                                      <CCol xs={1} className="justify-content-end">
                                        <CButton
                                          className="btn-collapse-orange"
                                          onClick={() => toggleSegmentoVisibilidad(carreteraIndex, index)} // Pasa el índice del segmento
                                        >
                                          {segmentoVisibilidad[carreteraIndex] && segmentoVisibilidad[carreteraIndex][index] ? (  // Usa el valor de segmentoVisibilidad
                                            <img src={iconArrowUp} alt="Selected Image" className="icon-arrow-collapse" />
                                          ) : (
                                            <img src={iconArrowDown} alt="Selected Image" className="icon-arrow-collapse" />
                                          )}
                                        </CButton>
                                        
                                      </CCol>
                                    </CRow>
                                    <CCollapse visible={segmentoVisibilidad[carreteraIndex] && segmentoVisibilidad[carreteraIndex][index]}>
                     
                                      <CCard className="mt-3">
                                        <CCardBody className='background-segmento-collapse'>
                                          <div className="mb-3 letra-contenido-2">
                                            <CRow>
                                              <CCol xs={12}>
                                                <CRow>
                                                  <CCol xs={3}>
                                                    <CFormInput className='letra-contenido-modal-input' size="sm" id={`id${index + 1}`} placeholder="Ingrese" hidden value={segmento.id} onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                      ...c,
                                                      segmentos: c.segmentos.map((s, j) => j === index ? { ...s, id: e.target.value } : s)
                                                    } : c))
                                                    } />
                                                    <CFormLabel htmlFor={`kmInicio${index + 1}`}>KM Inicio</CFormLabel>
                                                    <CFormInput className='letra-contenido-modal-input' size="sm" id={`kmInicio${index + 1}`} placeholder="Ingrese" value={segmento.kminicio} onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                      ...c,
                                                      segmentos: c.segmentos.map((s, j) => j === index ? { ...s, kminicio: e.target.value } : s)
                                                    } : c))
                                                    } />
                                                  </CCol>

                                                  <CCol xs={3}>
                                                    <CFormLabel htmlFor={`kmFin${index + 1}`}>KM Fin</CFormLabel>
                                                    <CFormInput className='letra-contenido-modal-input' size="sm" id={`kmFin${index + 1}`} placeholder="Ingrese" value={segmento.kmfin} onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                      ...c,
                                                      segmentos: c.segmentos.map((s, j) => j === index ? { ...s, kmfin: e.target.value } : s)
                                                    } : c))
                                                    } />
                                                  </CCol>

                                                  <CCol xs={6}>
                                                    <CFormLabel htmlFor={`longitudKm${index + 1}`}>Longitud (en KM)</CFormLabel>
                                                  <CFormInput className='letra-contenido-modal-input' size="sm" type='number' id={`longitudKm${index + 1}`} placeholder="00" value={segmento.longitudKm1} onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                      ...c,
                                                      segmentos: c.segmentos.map((s, j) => j === index ? { ...s, longitudKm1: e.target.value } : s)
                                                    } : c))
                                                    } />
                                                  </CCol>
                                                </CRow>
                                              </CCol>
                                            </CRow>
                                          </div>

                                          <div className="mb-3 letra-contenido-2">
                                            <CRow>
                                              <CCol xs={6}>
                                                <CFormSelect size="sm"
                                                  className='letra-contenido-modal-input'
                                                  label="Tipo de pavimento"
                                                  value={segmento.tipoPavimento}
                                                  onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                    ...c,
                                                    segmentos: c.segmentos.map((s, j) => j === index ? { ...s, tipoPavimento: e.target.value } : s)
                                                  } : c))
                                                  }>
                                                  <option className='fuente-letra-oscura' >Seleccione tipo</option>
                                                  <option className='fuente-letra-oscura' value="asfalto">Asfalto</option>
                                                  <option className='fuente-letra-oscura' value="hormigon">Hormigon</option>
                                                </CFormSelect>
                                              </CCol>
                                              <CCol xs={6}>
                                                <div>
                                                  <div className="datePicker-container"> {/* Contenedor para label y DatePicker */}
                                                    <CFormLabel htmlFor={`fecharelevamiento${index + 1}`}>Fecha de relevamiento</CFormLabel>
                                                    <DatePicker
                                                      ref={datePickerRef3} // Ya no necesitas una referencia única
                                                      id={`fecharelevamiento${index + 1}`} // Usa un ID único para cada DatePicker
                                                      selected={segmento.fechaRelevamiento} // Usa la fecha del segmento
                                                    onChange={date => handleFechaRelevamientoChange(date, carreteraIndex, index)}
                                                    // onChange={date => {
                                                    //   setCarreteras(prevCarreteras => prevCarreteras.map((c, i) =>
                                                    //     i === carreteraIndex ? {
                                                    //       ...c,
                                                    //       segmentos: c.segmentos.map((s, j) =>
                                                    //         j === index ? { ...s, fechaRelevamiento: date } : s
                                                    //       )
                                                    //     } : c
                                                    //   ));
                                                    // }}

                                                    customInput={
                                                      <CustomInput
                                                        value={segmento.fechaRelevamiento ? segmento.fechaRelevamiento.toLocaleDateString() : ""}
                                                        onChange={date => handleFechaRelevamientoChange(date, carreteraIndex, index)}
                                                        // onChange={date => {
                                                        //   setCarreteras(prevCarreteras => prevCarreteras.map((c, i) =>
                                                        //     i === carreteraIndex ? {
                                                        //       ...c,
                                                        //       segmentos: c.segmentos.map((s, j) =>
                                                        //         j === index ? { ...s, fechaRelevamiento: date } : s
                                                        //       )
                                                        //     } : c
                                                        //   ));
                                                        // }}
                                                        onFocus={handleFocus3}
                                                      />
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {errors[`segmentoError${carreteraIndex}-${index}`] && ( // Usa el guion en la clave del error
                                      <div className="text-danger small letra-contenido-6">
                                        {errors[`segmentoError${carreteraIndex}-${index}`]}
                                      </div>
                                    )}
                                            </CCol>
                                          </CRow>
                                        </div>

                                          <div className="mb-3 letra-contenido-2">
                                            <CRow>
                                              <CCol xs={12}>
                                                <CRow>
                                                  <CCol xs={12}>
                                                    <CFormLabel htmlFor="anchoCarril">Banquina</CFormLabel>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol xs={6}>
                                                    <CFormSelect size="sm"
                                                      className='letra-contenido-modal-input'
                                                      label="Tipo de banquina"
                                                      value={segmento.tipoBanquina}
                                                      onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                        ...c,
                                                        segmentos: c.segmentos.map((s, j) => j === index ? { ...s, tipoBanquina: e.target.value } : s)
                                                      } : c))
                                                      }>
                                                      <option className='fuente-letra-oscura' >Seleccione tipo</option>
                                                      <option className='fuente-letra-oscura' value="pavimentada">Pavimentada</option>
                                                      <option className='fuente-letra-oscura' value="noPavimentada">No Pavimentada</option>
                                                    </CFormSelect>
                                                  </CCol>
                                                  <CCol xs={6}>
                                                    <CFormSelect size="sm"
                                                      className='letra-contenido-modal-input'
                                                      label="Estado"
                                                      value={segmento.estadoBanquina}
                                                      onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                        ...c,
                                                        segmentos: c.segmentos.map((s, j) => j === index ? { ...s, estadoBanquina: e.target.value } : s)
                                                      } : c))
                                                      }>
                                                      <option className='fuente-letra-oscura' >Seleccione estado</option>
                                                      <option className='fuente-letra-oscura' value="bueno">Bueno</option>
                                                      <option className='fuente-letra-oscura' value="regular">Regular</option>
                                                      <option className='fuente-letra-oscura' value="malo">Malo</option>
                                                    </CFormSelect>
                                                  </CCol>

                                                </CRow>
                                              </CCol>

                                            </CRow>
                                          </div>

                                          <div className="mb-3 letra-contenido-2">
                                            <CRow>
                                              <CCol xs={12}>
                                                <CRow>
                                                  <CCol xs={6}>
                                                    <CFormLabel htmlFor={`anchoInterno${index + 1}`}>Ancho interno</CFormLabel>
                                                  <CFormInput className='letra-contenido-modal-input' size="sm" type='number' id={`anchoInterno${index + 1}`} placeholder="Ingrese" value={segmento.anchoInterno} onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                      ...c,
                                                      segmentos: c.segmentos.map((s, j) => j === index ? { ...s, anchoInterno: e.target.value } : s)
                                                    } : c))
                                                    } />
                                                  </CCol>

                                                  <CCol xs={6}>
                                                    <CFormLabel htmlFor={`anchoExterno${index + 1}`}>Ancho externo</CFormLabel>
                                                  <CFormInput className='letra-contenido-modal-input' size="sm" type='number' id={`anchoExterno${index + 1}`} placeholder="Ingrese" value={segmento.anchoExterno} onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                      ...c,
                                                      segmentos: c.segmentos.map((s, j) => j === index ? { ...s, anchoExterno: e.target.value } : s)
                                                    } : c))
                                                    } />
                                                  </CCol>

                                                </CRow>
                                              </CCol>
                                            </CRow>
                                          </div>

                                          <div className="mb-3 letra-contenido-2">
                                            <CRow>
                                              <CCol xs={12}>
                                                <CRow>
                                                  <CCol xs={12}>
                                                    <CFormLabel htmlFor="anchoCarril">Carril</CFormLabel>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol xs={6}>
                                                    <CFormSelect size="sm"
                                                      className='letra-contenido-modal-input'
                                                      label="Número de carriles"
                                                      value={segmento.numCarriles}
                                                      onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                        ...c,
                                                        segmentos: c.segmentos.map((s, j) => j === index ? { ...s, numCarriles: e.target.value } : s)
                                                      } : c))
                                                      }>
                                                      <option className='fuente-letra-oscura' >Seleccione estado</option>
                                                      <option className='fuente-letra-oscura' value="1">1</option>
                                                      <option className='fuente-letra-oscura' value="2">2</option>
                                                      <option className='fuente-letra-oscura' value="3">3</option>
                                                      <option className='fuente-letra-oscura' value="4">4</option>
                                                      <option className='fuente-letra-oscura' value="5">5</option>
                                                      <option className='fuente-letra-oscura' value="6">6</option>
                                                      <option className='fuente-letra-oscura' value="7">7</option>
                                                    </CFormSelect>
                                                  </CCol>
                                                  <CCol xs={6}>
                                                    <CFormSelect size="sm"
                                                      className='letra-contenido-modal-input'
                                                      label="Sentido de carril"
                                                      value={segmento.sentidoCarril}
                                                      onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                        ...c,
                                                        segmentos: c.segmentos.map((s, j) => j === index ? { ...s, sentidoCarril: e.target.value } : s)
                                                      } : c))
                                                      }>
                                                      <option className='fuente-letra-oscura' >Seleccione estado</option>
                                                      <option className='fuente-letra-oscura' value="ascendente">Ascendente</option>
                                                      <option className='fuente-letra-oscura' value="descendente">Descendente</option>
                                                    </CFormSelect>
                                                  </CCol>

                                                </CRow>
                                              </CCol>

                                            </CRow>
                                          </div>

                                          <div className="mb-3 letra-contenido-2">
                                            <CRow>
                                              <CCol xs={12}>
                                                <CRow>
                                                  <CCol xs={12}>
                                                    <CFormLabel htmlFor="anchoCarril">Calzada</CFormLabel>
                                                  </CCol>
                                                </CRow>
                                                <CRow>
                                                  <CCol xs={6}>
                                                    <CFormSelect size="sm"
                                                      className='letra-contenido-modal-input'
                                                      label="Tipo de separador"
                                                      value={segmento.tipoSeparador}
                                                      onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                        ...c,
                                                        segmentos: c.segmentos.map((s, j) => j === index ? { ...s, tipoSeparador: e.target.value } : s)
                                                      } : c))
                                                      }>
                                                      <option className='fuente-letra-oscura' >Seleccione</option>
                                                      <option className='fuente-letra-oscura' value="newJersey">New Jersey</option>
                                                      <option className='fuente-letra-oscura' value="barandaMelalica">Baranda Melálica</option>
                                                      <option className='fuente-letra-oscura' value="canteroCentral">Cantero Central</option>
                                                    </CFormSelect>
                                                  </CCol>
                                                  <CCol xs={6}>
                                                    <CFormLabel htmlFor={`anchoCalzada${index + 1}`}>Ancho de calzada</CFormLabel>
                                                  <CFormInput className='letra-contenido-modal-input' size="sm" type='number' id={`anchoCalzada${index + 1}`} placeholder="Ingrese" value={segmento.anchoCalzada} onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                      ...c,
                                                      segmentos: c.segmentos.map((s, j) => j === index ? { ...s, anchoCalzada: e.target.value } : s)
                                                    } : c))
                                                    } />
                                                  </CCol>

                                                </CRow>
                                              </CCol>

                                            </CRow>
                                          </div>

                                          <div className="mb-3 letra-contenido-2">
                                            <CRow>
                                              <CCol xs={6}>
                                                <CFormSelect size="sm"
                                                  className='letra-contenido-modal-input'
                                                  label="Estado de drenaje"
                                                  value={segmento.estadoDrenaje}
                                                  onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                    ...c,
                                                    segmentos: c.segmentos.map((s, j) => j === index ? { ...s, estadoDrenaje: e.target.value } : s)
                                                  } : c))
                                                  }>
                                                  <option className='fuente-letra-oscura' >Seleccione estado</option>
                                                  <option className='fuente-letra-oscura' value="bueno">Bueno</option>
                                                  <option className='fuente-letra-oscura' value="regular">Regular</option>
                                                  <option className='fuente-letra-oscura' value="malo">Malo</option>
                                                </CFormSelect>
                                              </CCol>
                                              <CCol xs={6}>
                                                <CFormSelect size="sm"
                                                  className='letra-contenido-modal-input'
                                                  label="Estado de borde de calzada"
                                                  value={segmento.estadoBordeCalzada}
                                                  onChange={(e) => setCarreteras(prevCarreteras => prevCarreteras.map((c, i) => i === carreteraIndex ? {
                                                    ...c,
                                                    segmentos: c.segmentos.map((s, j) => j === index ? { ...s, estadoBordeCalzada: e.target.value } : s)
                                                  } : c))
                                                  }>
                                                  <option className='fuente-letra-oscura' >Seleccione estado</option>
                                                  <option className='fuente-letra-oscura' value="bueno">Bueno</option>
                                                  <option className='fuente-letra-oscura' value="regular">Regular</option>
                                                  <option className='fuente-letra-oscura' value="malo">Malo</option>
                                                </CFormSelect>
                                              </CCol>
                                            </CRow>
                                          </div>

                                        </CCardBody>
                                      </CCard>
                                    </CCollapse>

                                    <br></br>
                                    <hr></hr>

                                  </CCard>
                                </CCol>
                              </CRow>
                            </div>
                          </div>
                        ))}

                      {/* Botón Agregar Segmento */}
                      <div className="mb-2">
                        <CRow>
                          <CCol xs={10}>
                            <CButton className="letra-btn-agregar align-inicio" onClick={() => agregarSegmento(carreteraIndex)}>
                              <span className='align-inicio'><img src={iconImagenUpload} alt="Selected Image" className="icon-add-form mr-2" /> Agregar Segmento</span>
                            </CButton>

                            <hr></hr>
                          </CCol>
                        </CRow>
                      </div>



                    </div>
                  ))}
                  {/* Botón Agregar Carretera */}
                  <div className="mb-2">
                    <CRow>
                      <CCol xs={10}>
                        <CButton className="letra-btn-agregar align-inicio" onClick={agregarCarretera}>
                          <span className='align-inicio'><img src={iconImagenUpload} alt="Selected Image" className="icon-add-form mr-2" /> Agregar Carretera</span>
                        </CButton>
                        {/* Botón de eliminar la última carretera */}
                        {/* {carreteras.length > 0 && carreteras[carreteras.length - 1].isNew === 1 && (
                          <CButton
                            className="btn-delete-orange"
                            onClick={eliminarUltimaCarretera}
                          >
                            <img src={trash} alt="Selected Image" className="icon-arrow-collapse" />
                          </CButton>
                        )} */}
                        <hr></hr>
                      </CCol>
                    </CRow>

                  </div>
                </CForm>
              </CCardBody>
            </CCard>

          </CCol>
        </CCol>
      </CRow>


      {profile === 'true' ? (
        <footer className='footer-form-video-estilo'>
          <CRow>
            {/* <CCol xs='2'></CCol>  */}
            <CCol xs='12'>
              <CRow>
                <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmit} className="px-8 btn-guardar-footer flex items-center justify-between" type="submit"><img src={iconCheck} alt="Selected Image" className="icon-check mr-2" /><span className='padding-separar-boton'>Guardar y salir</span></CButton></CCol>
                <CCol xs='1'>
                  <div className="">
                    <CButton href='/dashboard' className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
                  </div>
                </CCol>
                <CCol xs='1' className='padding-left-footer-next'>
                  <div className="">
                    <CButton className="px-8 btn-next-footer" type="submit">Siguiente</CButton>

                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </footer>
      ) : (
        <footer className='footer-form-video-estilo'>
          <CRow>
            <CCol xs='2'></CCol>
            <CCol xs='10'>
              <CRow>
                <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmit} className="px-8 btn-guardar-footer flex items-center justify-between" type="submit"><img src={iconCheck} alt="Selected Image" className="icon-check mr-2" /><span className='padding-separar-boton'>Guardar y salir</span></CButton></CCol>
                <CCol xs='1'>
                  <div className="">
                    <CButton href='/dashboard' className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
                  </div>
                </CCol>
                <CCol xs='1' className='padding-left-footer-next'>
                  <div className="">
                    <CButton href={`/new-video/form/${id}`} className="px-8 btn-next-footer" type="submit">Siguiente</CButton>

                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </footer>

      )}
      {showSuccessToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-exito"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Registro editado correctamente.</CToastBody>
            <CToastClose onClick={handleCloseToast} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}
      {showErrorToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-error"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Ha ocurrido un Error.</CToastBody>
            <CToastClose onClick={handleCloseToastError} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}

      {isLoading && ( // Mostrar el spinner solo si isLoading es true
        <div className="loading-overlay">
          <CSpinner color="primary" />
        </div>
      )}
    </>
  );
}


FormVideo.propTypes = {
  setProfile: PropTypes.func.isRequired

}

export default FormVideo;