import React, { useEffect } from 'react';

const SessionHandler = ({ children }) => { 
  const handleLogout = () => {
    localStorage.removeItem('accessToken'); 
    localStorage.removeItem('refreshToken'); 
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('total');
    localStorage.removeItem('id');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleLogout);
    return () => {
      window.removeEventListener('beforeunload', handleLogout); 
    };
  }, []);

  return (
   <>
    {children}
   </>
  ); // Renderiza el componente envuelto
};

export default SessionHandler;