import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import Inventory from '../components/Inventory/Inventory';
import Map from '../components/Map/Map';
import Login from '../components/Login/Login';
import Register from '../components/Register/Register'
import NewProjectForm from '../components/NewProject/NewProjectForm'
import ProjectFormEdit from '../components/NewProject/ProjectFormEdit'
import InsertVideo from '../components/InsertVideo/InsertVideo'
import NewUser from '../components/NewUser/NewUser'
import Gallery from '../components/Gallery/Gallery'
import ProfileUser from '../components/ProfileUser/ProfileUser'
import NewProjectComponet from '../components/NewProject/NewProjectComponent';
import Detail from '../components/Detail/Detail';
import DetailProject from '../components/Detail/DetailProject';

import useToken from '../components/App/useToken';


function AppRoutes({ token, profile, setProfile, setToken, navigate }) {
    // const { token, setToken } = useToken();
    // const navigate = useNavigate(); 
    // const [profile, setProfile] = useState(null);
  
    // ... tu lógica para manejar el token y el perfil
    useEffect(() => {
      if (!token) {
        console.log('no hay token')
        alert('redirecccion')
        navigate('/login'); // Redirige a /login si no hay token
      }
    }, [token, navigate]);
  
    return (
        <Routes>
          {/* Rutas protegidas por el token */}
          {token && (
            <>
              <Route path="/new-project" element={<NewProjectComponet />} />
  //             <Route path="/new-project/form" element={<NewProjectForm profile={profile} setProfile={setProfile} />} />
  //             <Route path="/project/form-edit/:id" element={<ProjectFormEdit profile={profile} setProfile={setProfile} />} />
  //             <Route path="/new-user/form" element={<NewUser profile={profile} setProfile={setProfile} />} />
  //             <Route path="/new-video/form/:id" element={<InsertVideo profile={profile} setProfile={setProfile} />} />
  //             <Route path="/gallery" element={<Gallery />} />
  //             <Route path="/profile/user" element={<ProfileUser setToken={setToken} profile={profile} setProfile={setProfile} />} />
  //             <Route path="/dashboard" element={<Dashboard profile={profile} setProfile={setProfile} />} />
  //             <Route path="/inventory" element={<Inventory />} />
  //             <Route path="/map" element={<Map profile={profile} setProfile={setProfile} />} />
  //             <Route path="/detail-project" element={<Detail profile={profile} setProfile={setProfile} />} />
  //             <Route path="/detail-project/:id" element={<DetailProject profile={profile} setProfile={setProfile} />} />
  //             <Route path="/login" element={<Navigate to="/dashboard" replace  />} />
  //             <Route path="/" element={<Navigate to="/login" replace  />} />
  //             <Route path="/register" element={<Navigate to="/dashboard" replace  />} />
              {/* ... tus otras rutas protegidas */}
            </>
          )}
    
    <>
  //         <Route path="/home" element={<Navigate to="/login" replace />} />
  //         <Route path="/new-project" element={<Navigate to="/login" replace />} />
  //         <Route path="/new-project/form" element={<Navigate to="/login" replace />} />
  //         <Route path="/project/form-edit/:id" element={< Navigate to="/login" replace />} />
  //         <Route path="/new-user/form" element={<Navigate to="/login" replace />} />
  //         <Route path="/new-video/form/:id" element={<Navigate to="/login" replace />} />
  //         <Route path="/gallery" element={<Navigate to="/login" replace />} />
  //         <Route path="/profile/user" element={<Navigate to="/login" replace />} />
  //         <Route path="/dashboard" element={<Navigate to="/login" replace />} />
  //         <Route path="/inventory" element={<Navigate to="/login" replace />} />
  //         <Route path="/map" element={<Navigate to="/login" replace />} />
  //         <Route path="/detail-project" element={<Navigate to="/login" replace />} />
  //         <Route path="/detail-project/:id" element={<Navigate to="/login" replace />} />
  //         <Route path="/" element={<Navigate to="/login" replace />} />
  //         <Route path="/login" element={<Login setToken={setToken} setProfile={setProfile} />} /> 
  //         <Route path="/register" element={<Register setToken={setToken} setProfile={setProfile} />} /> 
            
  //         </> 
        </Routes>
      );
    }
    
    export default AppRoutes;