import React, { useState, useEffect, useRef } from 'react';
import '../../scss/style.scss'

import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  // DocsExample,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilArrowLeft,
} from '@coreui/icons'

// import  imagenPerfil  from '../../assets/images/image-perfil.png'
import imagenPerfil from '../../assets/images/logoacya557.png'

async function senData(credentials) {
  //   return fetch(process.env.REACT_APP_URL_API+'/api/auth/login', {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/segmento/send-data`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

function DataUser({ profile, setProfile }) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');
  const [visible, setVisible] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Referencia al input de archivo
  const storedEmail = localStorage.getItem('email');
  const storedName = localStorage.getItem('name');
  const storedPhone = localStorage.getItem('phone');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Función para obtener datos del servidor
  function truncateText(text, maxLength, truncationText = "...") {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + truncationText;
    }
    return text;
  }

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleSelectImage = () => {
    fileInputRef.current.click(); // Simula un click en el input de archivo
  };

  useEffect(() => {
    // const handleScroll = () => {
    //   setShowFooter(window.scrollY > 100); // Ajusta el valor de desplazamiento para mostrar el pie de página
    // console.log('jsonData')
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // console.log('jsonData222')
        const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
        // console.log(storedName)
        const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
        // console.log(token)
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jwt/auth/profile`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        // console.log('jsonData333')
        // console.log(response)
        if (!response.ok) {
          // console.log('jsonData4444')
          if (response.status === 401) { 
            // Token inválido o expirado
            console.error("Error 401: No autorizado");
            // Redirige al inicio de sesión, limpia el token, etc.
            localStorage.removeItem('token');
            window.location.href = '/login'; // Ajusta la ruta si es necesario 
          } else {
            // Otros errores
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }
        }
        // console.log('jsonData555')
        const jsonData = await response.json();
        // console.log('jsonData666')
        setData(jsonData);
        // console.log(jsonData)
        localStorage.setItem('name', jsonData.name);
        localStorage.setItem('phone', jsonData.phone);
        localStorage.setItem('email', jsonData.email);
        localStorage.setItem('image', jsonData.image);
        // setToken(jsonData);
        // console.log('jsonData777')
        // console.log(jsonData)
        // console.log('jsonData888')
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }

    };
    fetchData();
    // window.addEventListener('scroll', handleScroll);
    // return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!videoFile) return;

    // const formData = new FormData();
    // formData.append('video', videoFile); // Nombre del campo 'video'
    // formData.append('longitud', longitud);
    // formData.append('nsegmento', nsegmento);
    // formData.append('anchocarril', anchocarril);
    // formData.append('ncarriles', ncarriles);
    // formData.append('tipopavimento', tipopavimento);
    // formData.append('estadopavimento', estadopavimento);

    // try {
    //   const response = await axios.get('http://localhost:4009/jwt/auth/profile', {
    //     headers: {
    //       'Access-Control-Allow-Origin': '*',
    //       'Content-Type': 'multipart/form-data' 
    //     }
    //   });

    //   console.log('Respuesta del servidor:', response.data);
    // } catch (error) {
    //   console.error('Error al subir el video:', error);
    // }
  };

  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs>
          <CCol xs='10' className='d-flex align-items-center padding-titulo-list-contenedor'>
            <CIcon icon={cilArrowLeft} size="xxl" className="height-auto icon-background-orange" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Datos de cuenta</strong></h5>
          </CCol>
          <CCol xs={8} style={{ 'height': '29rem' }}>
            <CCard className="mb-4 scroll-contenedor-new-proyect" style={{ 'height': '300px' }}>
              {/* <CCol xs={6} className='titulo-formulario-new-proyect'><h6>Datos personales</h6></CCol> */}
              <CCardBody>

                {/* <DocsExample href="forms/form-control"> */}
                <CForm onSubmit={handleSubmit}>
                  {/* <div>
  <form id="inventarioVialForm" action="/submit-inventario" method="POST"> */}
                  <div className="mb-3">
                    <CRow>
                      <CCol xs='8'>
                        <CCardBody>
                          <CCardText className='text-card-frame' width="800" height="140">
                            <CRow>
                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  {/* <CTableHead> */}
                                  <CTableRow>
                                    <CTableDataCell className='titulo-table-padding' scope="col">Datos personales</CTableDataCell>

                                  </CTableRow>
                                  {/* </CTableHead> */}
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="padding-botoom-1 letra-titulo-2">
                                        <strong>Nombre de usuario</strong>
                                      </CTableDataCell>
                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="padding-botoom-1 letra-contenido">
                                        {storedName}
                                      </CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-with letra-titulo-2">
                                        <strong>Email</strong>
                                      </CTableDataCell>
                                      <CTableDataCell className="padding-botoom-1 letra-titulo-2">
                                        <strong>Teléfono</strong>
                                      </CTableDataCell>
                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="padding-botoom-1 letra-contenido">
                                        {storedEmail}
                                      </CTableDataCell>
                                      <CTableDataCell className="padding-botoom-1 letra-contenido">
                                        {/* +1 202 360 4623 */}
                                        {storedPhone}
                                      </CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                              </CCol>
                            </CRow>
                            <CRow>
                              {/* <CCol xs='12'>
                <CTable borderless className='margin-bottom-table'>
              
                  <CTableRow>
                    <CTableDataCell className='titulo-table-padding' scope="col">Contraseña</CTableDataCell>
                  
                  </CTableRow>
     
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="padding-botoom-1 letra-titulo-2">
                        <strong>Contraseña actual</strong>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="padding-botoom-1 letra-contenido">
                        Señaletica de tránsito
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable> 
              </CCol> */}
                            </CRow>
                          </CCardText>
                        </CCardBody>
                        {/* <div className="mb-3">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="nombre">Nombre de usuario</CFormLabel>
          <CFormInput size="sm" id="nombre" placeholder="Ingrese nombre" value={longitud} onChange={(e) => setLongitud(e.target.value)}/>
            </CCol>
            </CRow>
          </div> */}

                        {/* <div className="mb-3">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="email">Email</CFormLabel>
          <CFormInput size="sm" id="email" placeholder="Ingrese nombre" value={longitud} onChange={(e) => setLongitud(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
          <CFormLabel htmlFor="telefono">Telèfono</CFormLabel>
          <CFormInput size="sm" id="telefono" placeholder="Ingrese apellido" value={longitud} onChange={(e) => setLongitud(e.target.value)}/>
            </CCol>
            </CRow>
          </div> */}

                        {/* <div className="mb-3">
                <CRow>
                <CCol xs={12}>
                  <CRow>
                  <CCol xs={12}>
                  <CFormLabel htmlFor="anchoCarril">Contraseña</CFormLabel>
                  </CCol>
                  </CRow>
                  <CRow>
                  <CCol xs={6}>
                  <CFormLabel htmlFor="actualContrasena">Contraseña actual</CFormLabel>
                  <CFormInput size="sm"  id="actualContrasena" placeholder="Ingrese contraseña" value={anchocarril} onChange={(e) => setAnchocarril(e.target.value)}/>
                  </CCol> 
                  </CRow>
                  </CCol>
                    
                  </CRow>
                  
                </div> */}
                      </CCol>
                      {/* <CCol xs='1'></CCol> */}
                      <CCol xs='4' className='quitar-padding with-column-dateuser'>
                        <CCard className='container-upload-file'>
                          <div className="image-uploader-avatar3">
                            {selectedImage ? (
                              <img src={URL.createObjectURL(selectedImage)} alt="Selected Image" className="avatar3" />
                            ) : (
                              <img src={imagenPerfil} alt="Selected Image" className="avatar3" />
                            )}
                            <CFormInput
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className="file-input"
                              ref={fileInputRef}
                            />
                            <span> <strong><div
                              className="truncate-text"
                              title={truncateText(storedName, 20) === storedName ? '' : storedName}
                            >
                              {/* Si se trunca, muestra el tooltip con el nombre completo */}
                              {truncateText(storedName, 20)}
                            </div></strong></span>
                            <span><div
                              className="truncate-text"
                              title={truncateText(storedEmail, 20) === storedEmail ? '' : storedEmail}
                            >
                              {/* Si se trunca, muestra el tooltip con el nombre completo */}
                              {truncateText(storedEmail, 20)}
                            </div></span>
                            {/* <CButton className="btn-search-list" onClick={handleSelectImage} ><CIcon className='icon-button-upload' icon={cilArrowTop} style={{'--ci-primary-color': 'black'}} />Seleccionar imagen</CButton> */}
                          </div>
                        </CCard>
                      </CCol>
                    </CRow>
                  </div>
                </CForm>





              </CCardBody>
            </CCard>

          </CCol>
        </CCol>
      </CRow>

      <footer className='footer-form-video-estilo'>
        <CRow>
          <CCol xs='12'>
            <CRow>
              <CCol xs='8' className='footer-text-save-leave'></CCol>
              <CCol xs='1'>
                <div className="">
                  <CButton href='/dashboard' className="px-8 btn-salir-footer" type="submit">Salir</CButton>
                </div>
              </CCol>
              {/* <CCol xs='1' className='quitar-padding'>
          
    </CCol> */}
              <CCol xs='1' className='padding-left-footer-next'>
                <div className="">
                  <CButton href='/new-user/form' className="px-8 btn-next-footer" type="submit">Editar</CButton>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </footer>
    </>
  );
}


export default DataUser;