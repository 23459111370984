import React, { useState } from 'react';
import AppSidebar from '../AppSidebar';
// import Widgets from '../widgets/Widgets';
import FormUser from '../Form/FormUser';

// import {
 
//   CRow,
// } from '@coreui/react'

function NewUser({ profile, setProfile }) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');

  return (
    <div>
      <AppSidebar setProfile={setProfile} />
      <div className="wrapper d-flex flex-column min-vh-100">
        {/* <Header profile={profile} />  */}
        <div className="body flex-grow-1">
          <FormUser setProfile={setProfile} /> 
        </div>
      </div>
    </div>
  );
}

export default NewUser;