import React from 'react';

const CircleStatus = ({ statusSalad }) => {
  let circleClass = 'circle.png'; // Valor inicial gris

  switch (statusSalad) {
    case 'stopped':
      circleClass = 'circle-rojo.png';
      break;
    case 'deploying':
      circleClass = 'circle-amarillo.png';
      break;
    case 'running':
      circleClass = 'circle-verde.png';
      break;
    default:
      circleClass = 'circle-gris.png';
  }

  const circleImage = require(`../../assets/images/${circleClass}`); 

  return (
    <>
      <img
        src={circleImage} // Ajusta la ruta según tu estructura
        alt="Selected Image"
        className="mr-2"
        style={{ width: '11px', height: '11px', objectFit: 'contain' }}
      />
      <span className="padding-separar-boton-descargar letra-contenido-5">{statusSalad}</span>
    </>
  );
};

export default CircleStatus;