import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CIcon } from '@coreui/icons-react';
import * as icons from '@coreui/icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'; 
import { icon } from 'leaflet';
import { useMap } from 'react-leaflet/hooks';
import { useMapEvents } from 'react-leaflet/hooks';
import "leaflet/dist/leaflet.css";
import '../../index.css';

import { 
  CRow,
} from '@coreui/react';

import {
  cibCcAmex,
} from '@coreui/icons'

export default function DetailFound({ profile, setProfile }) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [senaleticaReclasificada, setSenaleticaReclasificada] = useState(null);
  const tableRef = useRef(null); // Referencia para la tabla
  
  useEffect(() => {
    // console.log('jsonData')
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // console.log('jsonData222')
        const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
        const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
        // console.log(token)
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/last-project`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        // console.log('jsonData333')
        console.log(response)
        if (!response.ok) {
          if (response.status === 401) { 
            // Token inválido o expirado
            console.error("Error 401: No autorizado");
            // Redirige al inicio de sesión, limpia el token, etc.
            localStorage.removeItem('token');
            window.location.href = '/login'; // Ajusta la ruta si es necesario 
          } else {
            // Otros errores
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }
        }
        // console.log('jsonData555')
        const jsonData = await response.json();
        // console.log('jsonData666')
        setData(jsonData);
        // console.log(jsonData)
        // localStorage.setItem('NameProyectoDetalles', jsonData.nombre);
        // localStorage.setItem('phone', jsonData.phone);
        // localStorage.setItem('email', jsonData.email);
        // localStorage.setItem('image', jsonData.image);
        // setToken(jsonData);
        // console.log('jsonData777')
        // console.log(jsonData[0].id)
        // console.log('jsonData888')
        navigate(`/detail-project/${jsonData[0].id}`); // Redirige si la data se obtiene correctamente
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    
    };
    fetchData();
  },[]);

  return (
    <>
    <CRow className='margin-row-contenedor'>
    </CRow>
    </>
  );
}


