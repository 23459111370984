import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../../scss/style.scss'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CTable,
  CTableRow,
  CTableHeaderCell,
  CToast,
  CToastBody,
  CToastClose,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilCheck,
  cilMedicalCross,
  cilArrowLeft,
} from '@coreui/icons'

import iconImagenUpload from '../../assets/images/icon-imagen-upload.png'
import trash from '../../assets/images/trash.png'
import movie from '../../assets/images/movie.png'
import iconCheck from '../../assets/images/check.png'

async function senData(credentials) {
  //   return fetch(process.env.REACT_APP_URL_API+'/api/auth/login', {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/video/send-data`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

function FormInsert({ profile, setProfile }) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');
  const [videoFile, setVideoFile] = useState(null);
  const [kminicio, setKminicio] = useState(null);
  const [kmfin, setKmfin] = useState(null);
  const [tipoCamara, setTipoCamara] = useState(null);
  const [segmento, setSegmento] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Referencia al input de archivo
  const fileNameRef = useRef(null); // Referencia al elemento donde mostrar el nombre
  const referenciaEntradaArchivo = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataProyecto, setDataProyecto] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  // const [formularios, setFormularios] = useState([]);
  const [formularios, setFormularios] = useState([
    { id: 1, video: null, kminicio: '', kmfin: '', tipoCamara: '', segmento: '' }
  ]);

  const [errors, setErrors] = useState({});

  const handleGoBack = () => {
    navigate(-1); // Navega a la página anterior
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formularioId = parseInt(e.target.getAttribute('data-formulario-id'), 10);

    const formularioIndex = formularios.findIndex(form => form.id === formularioId);

    if (file && formularioIndex !== -1) {
      setFormularios(formularios.map((form, index) => {
        if (index === formularioIndex) {
          return {
            ...form,
            video: {
              file: file, 
              name: file.name 
            }
          };
        }
        
        return form;
      }));
  
      // Elimina el mensaje de error después de agregar el video
      setErrors(prevErrors => ({
        ...prevErrors,
        [`video-${formularioIndex}`]: undefined // Eliminar el error del formulario específico
      }));
    }
  };

  const handleSelectImage = (formularioId) => {
    const input = fileInputRef.current;
    input.setAttribute('data-formulario-id', formularioId); // Agrega un atributo al input
    input.click();
  };

  const handleCloseToast = (element) => {
    setShowSuccessToast(false);
    navigate('/dashboard');
  }

  const handleCloseToastError = (element) => {
    setShowErrorToast(false);
  }

  // Función para validar cada formulario
  const validarFormulario = (formularios) => {
    const newErrors = {};
    formularios.forEach((formulario, index) => {
      if (!formulario.kminicio) {
        newErrors[`kminicio-${index}`] = 'Este campo es requerido';
      }
      if (!formulario.kmfin) {
        newErrors[`kmfin-${index}`] = 'Este campo es requerido';
      }
      if (!formulario.tipoCamara) {
        newErrors[`tipoCamara-${index}`] = 'Este campo es requerido';
      }
      if (!formulario.segmento) {
        newErrors[`segmento-${index}`] = 'Este campo es requerido';
      }
      if (!formulario.video) {
        newErrors[`video-${index}`] = 'Debes seleccionar un video';
      }

    });
    return newErrors;
  };

  useEffect(() => {
    setShowErrorToast(false)
    setShowSuccessToast(false)
    // const handleScroll = () => {
    //   setShowFooter(window.scrollY > 100); // Ajusta el valor de desplazamiento para mostrar el pie de página
    // };

    // window.addEventListener('scroll', handleScroll);
    // return () => window.removeEventListener('scroll', handleScroll);
    if (fileNameRef.current && selectedImage) {
      fileNameRef.current.textContent = selectedImage.name;
    }
  }, [selectedImage]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Muestra un indicador de carga mientras se realiza la solicitud

      try {
        const tokenData = localStorage.getItem('token'); // Obtén el token desde el localStorage
        const token = tokenData ? JSON.parse(tokenData).token : null; // Parsear el token JSON

        if (!token) {
          throw new Error('Token no disponible en localStorage'); // Manejar el caso en el que no haya token
        }

        // Realiza la solicitud a la API con el token en los headers
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/segmento-list/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        // Si la respuesta no es correcta, lanza un error
        if (!response.ok) {
          if (response.status === 401) { 
            // Token inválido o expirado
            console.error("Error 401: No autorizado");
            // Redirige al inicio de sesión, limpia el token, etc.
            localStorage.removeItem('token');
            window.location.href = '/login'; // Ajusta la ruta si es necesario 
          } else {
            // Otros errores
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }
        }

        // Obtén los datos JSON de la respuesta
        const jsonData = await response.json();
        console.log('Datos JSON recibidos:', jsonData); // Imprime los datos recibidos

        // Actualiza el estado de dataProyecto con los datos recibidos
        setDataProyecto(jsonData);

        // Guarda los datos en el localStorage si es necesario
        localStorage.setItem('data-segmento', JSON.stringify(jsonData));

      } catch (error) {
        console.error('Error durante la solicitud:', error); // Manejo de errores
        setError(error); // Guarda el error en el estado para mostrarlo en la interfaz
      } finally {
        setIsLoading(false); // Detén el indicador de carga
      }
    };

    fetchData(); // Llama a la función fetchData cuando el componente se monte o cambie el id

    // Este useEffect se ejecutará siempre que cambie `id` y asegurará que los datos se recarguen
  }, [id]);

  useEffect(() => {
    setFormularios([
      {
        id: Date.now(),
        videos: [],
        kminicio: '',
        kmfin: '',
        tipoCamara: '',
        segmento: ''
      }
    ]);
  }, []);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    // 1. Validación del formulario (sin cambios)
    const newErrors = validarFormulario(formularios);
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length > 0) {
      setIsLoading(false);
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('id', id); // Agrega el ID del proyecto
      formData.append('kminicio', formularios[0].kminicio); // Asumiendo que kminicio, etc. son iguales para todos los formularios. Ajusta si no es así.
      formData.append('kmfin', formularios[0].kmfin);
      formData.append('tipoCamara', formularios[0].tipoCamara);
      formData.append('segmento', formularios[0].segmento);
  
      let videoIndex = 0;
      for (const formulario of formularios) {
        if (formulario.video) {
          formData.append(`video${videoIndex}`, formulario.video.file);
          videoIndex++;
        }
      }
  
      const tokenData = localStorage.getItem('token');
      const token = tokenData ? JSON.parse(tokenData).token : null;
  
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/video/send-data`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`, // Eliminar 'Access-Control-Allow-Origin' y 'Content-Type' (axios lo maneja automáticamente)
        },
      });
  
      if (response.status === 200) { // Verifica si la respuesta es exitosa
        // const data = await response.json(); // Convierte el cuerpo de la respuesta a JSON
        // console.log('Respuesta del servidor:', data);
        setShowErrorToast(false)
        setShowSuccessToast(true);
      }
      

    } catch (error) {
      if (error.status === 401) { 
        // Token inválido o expirado
        console.error("Error 401: No autorizado");
        // Redirige al inicio de sesión, limpia el token, etc.
        localStorage.removeItem('token');
        window.location.href = '/login'; // Ajusta la ruta si es necesario 
        console.error('Error al subir el video:', error);
      }

      setShowErrorToast(true)
      console.error('Error al subir el video:', error);
    } finally {
      setIsLoading(false); // Ocultar el spinner al finalizar la carga
    }
  };

  const agregarFormulario = () => {
    setFormularios([...formularios, {
      id: Date.now(), // ID único para cada formulario
      videos: [], // Array para los videos del formulario
      kminicio: '',
      kmfin: '',
      longitud: '',
      tipoCamara: '',
      segmento: ''
    }]);
  };


  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs>
          <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
            {/* <CButton onClick={handleGoBack} className="px-8 btn-salir-footer" type="submit">Regresar</CButton> */}
            <CIcon onClick={handleGoBack} icon={cilArrowLeft} size="xxl" className="height-auto icon-background-orange cursor-pointer" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Nuevo Proyecto</strong></h5>
          </CCol>
          <CCol xs={10} style={{ 'height': '29rem' }}>
            <CCard className="mb-4 scroll-contenedor-new-proyect" style={{ 'height': '500px' }}>
              <CCol xs={10} className='titulo-formulario-new-video'>Proyecto ID # {id}</CCol>
              <CCardBody>

                <CForm onSubmit={handleSubmitForm}>
                  {formularios.map((form, index) => (
                    <div key={form.id}>
                      <h5 className='letra-contenido-2'>Formulario {index + 1}</h5>

                      <div className="mb-3">
                        <CRow>
                          <CCol xs={12}>
                            <CFormLabel className='letra-titulo-1' htmlFor="anchoCarril">Carga de Videos</CFormLabel>
                          </CCol>
                        </CRow>
                        <CCol xs={10}>
                          <CFormLabel className='letra-contenido-2' htmlFor="Video">Video</CFormLabel>
                          <CCol xs='12' className='quitar-padding'>
                            <CCard className='container-upload-video'>
                              <div className="image-uploader-form-video">
                                <CFormInput
                                  type="file"
                                  accept="video/mp4,video/webm,video/ogg,audio/mpeg,audio/wav"
                                  onChange={(e) => handleFileChange(e, form.id)}
                                  className="file-input"
                                  ref={fileInputRef}
                            
                                />
                                <CButton className="btn-form-video" onClick={() => handleSelectImage(form.id)} ><img src={iconImagenUpload} alt="Selected Image" className="avatar2-video" /></CButton>
                                <span className='letra-contenido'>Arrastra aquí tus videos para subir</span>
                                <span className='letra-contenido'>Formatos MP3, AVI, MOV</span>
                              </div>
                            </CCard>
                            {errors[`video-${index}`] && (
                              <div className="text-danger small letra-contenido-6">
                                {errors[`video-${index}`]}
                              </div>
                            )}
                          </CCol>

                        </CCol>

                      </div>

                      {/* Resto del formulario */}
                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={5}>
                            <CFormLabel htmlFor="kminicio">KM Inicio</CFormLabel>
                            <CFormInput className='letra-contenido-modal-input' size="sm" id="kminicio" placeholder="Ingrese kilometros" value={form.kminicio} onChange={(e) => {
                              // Actualiza el estado del formulario
                              const updatedFormularios = [...formularios];
                              updatedFormularios[index].kminicio = e.target.value;
                              setFormularios(updatedFormularios);

                              // Elimina el error si se corrige el campo
                              if (e.target.value) {
                                setErrors(prevErrors => ({
                                  ...prevErrors,
                                  [`kminicio-${index}`]: undefined
                                }));
                              }
                            }}

                            />
                            {errors[`kminicio-${index}`] && (
                              <div className="text-danger small">
                                {errors[`kminicio-${index}`]}
                              </div>
                            )}
                          </CCol>

                          <CCol xs={5}>
                            <CFormLabel htmlFor="kmfin">KM Fin</CFormLabel>
                            <CFormInput className='letra-contenido-modal-input' size="sm" id="kmfin" placeholder="Ingrese kilometros" value={form.kmfin} onChange={(e) => {
                              // Actualiza el estado del formulario
                              const updatedFormularios = [...formularios];
                              updatedFormularios[index].kmfin = e.target.value;
                              setFormularios(updatedFormularios);

                              // Elimina el error si se corrige el campo
                              if (e.target.value) {
                                setErrors(prevErrors => ({
                                  ...prevErrors,
                                  [`kmfin-${index}`]: undefined
                                }));
                              }
                            }}
                            />
                            {errors[`kmfin-${index}`] && (
                              <div className="text-danger small">
                                {errors[`kmfin-${index}`]}
                              </div>
                            )}
                          </CCol>
                        </CRow>
                      </div>

                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={5}>
                            <CFormSelect size="sm"
                              className='letra-contenido-modal-input'
                              label="Tipo de Cámara"
                              onChange={(e) => {
                                // Actualiza el estado del formulario
                                const updatedFormularios = [...formularios];
                                updatedFormularios[index].tipoCamara = e.target.value;
                                setFormularios(updatedFormularios);

                                // Elimina el error si se corrige el campo
                                if (e.target.value) {
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    [`tipoCamara-${index}`]: undefined
                                  }));
                                }
                              }}>
                              <option className='fuente-letra-oscura' >Seleccione tipo</option>
                              <option className='fuente-letra-oscura' value="frontal1">Frontal-1</option>
                              <option className='fuente-letra-oscura' value="frontal2">Frontal-2</option>
                              <option className='fuente-letra-oscura' value="cenital1">Cenital-1</option>
                              <option className='fuente-letra-oscura' value="cenital2">Cenital-2</option>
                            </CFormSelect>
                            {errors[`tipoCamara-${index}`] && (
                              <div className="text-danger small">
                                {errors[`tipoCamara-${index}`]}
                              </div>
                            )}
                          </CCol>
                          <CCol xs={5}>
                            <CFormSelect size="sm"
                              className='letra-contenido-modal-input'
                              label="Segmento"
                              onChange={(e) => {
                                // Actualiza el estado del formulario
                                const updatedFormularios = [...formularios];
                                updatedFormularios[index].segmento = e.target.value;
                                setFormularios(updatedFormularios);

                                // Elimina el error si se corrige el campo
                                if (e.target.value) {
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    [`segmento-${index}`]: undefined
                                  }));
                                }
                              }}>
                              {/* Opción por defecto */}
                              <option className='fuente-letra-oscura' value="">Seleccione Segmento</option>

                              {/* Renderizar opciones del array */}
                              {dataProyecto && dataProyecto.map((segmento) => (
                                <option
                                  key={segmento.id}
                                  className='fuente-letra-oscura'
                                  value={segmento.id}
                                >
                                  {segmento.nombre}
                                </option>
                              ))}
                            </CFormSelect>
                            {errors[`segmento-${index}`] && (
                              <div className="text-danger small">
                                {errors[`segmento-${index}`]}
                              </div>
                            )}
                          </CCol>
                        </CRow>
                      </div>

                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={10}>
                            <CTable bordered small className='table-spam-movie'>
                              <CTableRow>
                              {form.video && (
                                  <CTableHeaderCell
                                    key={form.id} // Agrega un key único a cada fila de la tabla
                                    id="fileName"
                                    className='letra-contenido'
                                    scope="col"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <CButton className='btn-movie-nf' size="sm">
                                        <img src={movie} alt="Descripción de la imagen" className="nav-icon width-img-search" />
                                      </CButton>
                                      <span className='spam-file-movie'>{form.video.name}</span>
                                    </div>
                                    <CButton variant="outline-danger" size="sm"  onClick={() => {
                          // Actualiza el estado del formulario para eliminar el video
                          setFormularios(formularios.map(f => {
                            if (f.id === form.id) {
                              return { ...f, video: null }; // Elimina el video
                            }
                            return f;
                          }));
                        }}>
                                      <img src={trash} alt="Descripción de la imagen" className="nav-icon width-img-search" />
                                    </CButton>
                                  </CTableHeaderCell>
                                )}
                              </CTableRow>

                            </CTable>
                          </CCol>
                        </CRow>
                      </div>



                    </div>
                  ))}
                  {/* Boton para agregar nuevo formulario */}
                  <div className="mb-3">
                    <CRow className='delete-border-color'>
                      <CCol xs={10}>
                        <CCard className="mt-3 delete-border-color" >
                          <CRow>
                            <div className="mb-3 letra-btn-agregar">
                              <CRow>
                                <CCol xs={12}>
                                  <CButton className="letra-btn-agregar align-inicio" onClick={agregarFormulario}>
                                    <span className='align-inicio'><img src={iconImagenUpload} alt="Selected Image" className="icon-add-form mr-2" /> Agregar Video</span>
                                  </CButton>
                                </CCol>
                              </CRow>
                            </div>
                          </CRow>
                        </CCard>
                      </CCol>
                    </CRow>
                  </div>
                  {/* <CButton type="submit" className="btn-submit-form">
        Guardar
      </CButton> */}
                </CForm>

              </CCardBody>
            </CCard>

          </CCol>
        </CCol>
      </CRow>
      {profile === 'true' ? (
        <footer className='footer-form-video-estilo'>
          <CRow>
            {/* <CCol xs='2'></CCol>  */}
            <CCol xs='12'>
              <CRow>
                <CCol xs='8' className='footer-text-save-leave letra-contenido-2'></CCol>
                {/* <CCol xs='1'>
                  <div className="">
                    <CButton onClick={handleGoBack} className="px-8 btn-salir-footer" type="submit">Regresar</CButton>
                  </div>
                </CCol> */}
                <CCol xs='8' className='padding-left-footer-next'>
                  {/* <div className="">
                    <CButton onClick={handleSubmit} className="px-8 btn-next-footer" type="submit">Procesar</CButton>

                  </div> */}
                  <CButton onClick={handleSubmitForm} className="footer-text-save-leave letra-contenido-2" type="submit"><img src={iconCheck} alt="Selected Image" className="icon-check" /><span className='padding-separar-boton'>Guardar y salir</span></CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </footer>
      ) : (
        <footer className='footer-form-video-estilo'>
          <CRow>
            <CCol xs='2'></CCol>
            <CCol xs='10'>
              <CRow>
                <CCol xs='4' className='footer-text-save-leave letra-contenido-2'></CCol>
                {/* <CCol xs='1'>
                  <div className="">
                    <CButton onClick={handleGoBack} className="px-8 btn-salir-footer" type="submit">Regresar</CButton>
                  </div>
                </CCol> */}
                <CCol xs='8' className='padding-left-footer-next'>
                  {/* <div className="">
                    <CButton onClick={handleSubmit} className="px-8 btn-next-footer" type="submit">Procesar</CButton>

                  </div> */}
                  <CButton onClick={handleSubmitForm} className="footer-text-save-leave letra-contenido-2" type="submit"><img src={iconCheck} alt="Selected Image" className="icon-check" /><span className='padding-separar-boton'>Guardar y salir</span></CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </footer>
      )}
      {showSuccessToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-exito"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Video Subido correctamente.</CToastBody>
            <CToastClose onClick={handleCloseToast} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}

      {showErrorToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-error"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Ha ocurrido un Error.</CToastBody>
            <CToastClose onClick={handleCloseToastError} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}

      {isLoading && ( // Mostrar el spinner solo si isLoading es true
        <div className="loading-overlay">
          <CSpinner color="primary" />
        </div>
      )}
    </>
  );
}

FormInsert.propTypes = {
  setProfile: PropTypes.func.isRequired

}

export default FormInsert;