import React, { useState, useEffect, useRef } from 'react';
// import ReactPlayer from 'react-player';
import axios from 'axios';
import '../../scss/style.scss'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSwitch,
  CToast,
  CToastBody,
  CToastClose,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilArrowLeft,
} from '@coreui/icons'

import imagenPerfil from '../../assets/images/logoacya557.png'

function FormUser({ profile, setProfile }) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');
  const [username, setUsername] = useState(localStorage.getItem('name'));
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [actualPassword, setActualPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(localStorage.getItem('phone'));
  const [image, setImage] = useState(null);
  const [confirmarPassword, setConfirmarPassword] = useState(null);
  // const [visible, setVisible] = useState(false);
  // const [showFooter, setShowFooter] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Referencia al input de archivo
  const storedEmail = localStorage.getItem('email');
  const storedPhone = localStorage.getItem('phone');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const storedName = localStorage.getItem('name');
  const [changePassword, setChangePassword] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const [errors, setErrors] = useState({
    username: '',
    actualPassword: '',
    password: '',
    confirmarPassword: '',
    // ... Errores para los campos de carretera
  });

  // Función para validar los campos
  const validarFormulario = () => {
    let newErrors = {};

    // Validar campos de Proyecto
    if (!username) {
      newErrors.username = 'Este campo es obligatorio';
    }
    if (changePassword) {
      if (!actualPassword) {
        newErrors.actualPassword = 'Este campo es obligatorio';
      }
      if (!password) {
        newErrors.password = 'Este campo es obligatorio';
      }
      if (!confirmarPassword) {
        newErrors.confirmarPassword = 'Este campo es obligatorio';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };


  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleSelectImage = () => {
    fileInputRef.current.click(); // Simula un click en el input de archivo
    // image = 'image'
  };

  const handleCloseToast = (element) => {
    setShowSuccessToast(false);
  }

  const handleCloseToastError = (element) => {
    setShowErrorToast(false);
  }

  useEffect(() => {
    // const handleScroll = () => {
    //   setShowFooter(window.scrollY > 100); // Ajusta el valor de desplazamiento para mostrar el pie de página

    // };

    // window.addEventListener('scroll', handleScroll);
    // return () => window.removeEventListener('scroll', handleScroll);
    // console.log('jsonData')
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // console.log('jsonData222')
        const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
        const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
        // console.log(token)
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jwt/auth/profile`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        // console.log('jsonData333')
        // console.log(response)
        if (!response.ok) {
          // console.log('jsonData4444')
          if (response.status === 401) { 
            // Token inválido o expirado
            console.error("Error 401: No autorizado");
            // Redirige al inicio de sesión, limpia el token, etc.
            localStorage.removeItem('token');
            window.location.href = '/login'; // Ajusta la ruta si es necesario 
          } else {
            // Otros errores
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }
        }
        // console.log('jsonData555')
        const jsonData = await response.json();
        // console.log('jsonData666')
        setData(jsonData);
        // console.log(jsonData)
        localStorage.setItem('name', jsonData.name);
        localStorage.setItem('phone', jsonData.phone);
        localStorage.setItem('email', jsonData.email);
        localStorage.setItem('image', jsonData.image);
        // setToken(jsonData);
        // console.log('jsonData777')
        // console.log(jsonData)
        // console.log('jsonData888')
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }

    };
    fetchData();
    // window.addEventListener('scroll', handleScroll);
    // return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSuccessToast(false);
    setShowErrorToast(false)
    if (validarFormulario()) { // Validar antes de envia
      setIsLoading(true);
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null;
      const formData = new FormData();
      formData.append('username', username); // Nombre del campo 'video'
      formData.append('email', email);
      formData.append('actualPassword', actualPassword);
      formData.append('password', password);
      formData.append('confirmarPassword', confirmarPassword);
      formData.append('phone', phone);
      formData.append('image', image);
      formData.append('changePassword', changePassword);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/jwt/auth/updateProfile`, formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          }
        });
        // console.log('Respuesta del servidor:', response.data);
        setShowSuccessToast(true);
      } catch (error) {
        if (error.response.status === 401) { 
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
          console.error('Error al subir el video:', error);
        }
        setShowErrorToast(true)
        // console.error('Error al actualizar datos:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs>
          <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
            <CIcon icon={cilArrowLeft} size="xxl" className="height-auto icon-background-orange" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Datos de cuenta</strong></h5>
          </CCol>
          <CCol xs={10} style={{ 'height': '29rem' }}>
            <CCard className="mb-4 scroll-contenedor-new-proyect" style={{ 'height': '500px' }}>
              <CCol xs={8} className='titulo-formulario-new-user'>Datos personales</CCol>
              <CCardBody>

                {/* <DocsExample href="forms/form-control"> */}
                <CForm onSubmit={handleSubmit}>
                  {/* <div>
  <form id="inventarioVialForm" action="/submit-inventario" method="POST"> */}
                  <div className="mb-3">
                    <CRow>
                      <CCol xs='8'>
                        <div className="mb-3 letra-contenido-2">
                          <CRow>
                            <CCol xs={12}>
                              <CFormLabel htmlFor="username">Nombre de usuario</CFormLabel>
                              <CFormInput className='letra-contenido-modal-input' size="sm" id="username" placeholder="Ingrese nombre" value={username} onChange={(e) => {
                                // Actualiza el estado del formulario

                                setUsername(e.target.value);

                                // Elimina el error si se corrige el campo
                                if (e.target.value) {
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    [`username`]: undefined
                                  }));
                                }
                              }} />
                              {errors.username && <div className="text-danger">{errors.username}</div>} {/* Mostrar error */}
                            </CCol>
                          </CRow>
                        </div>

                        <div className="mb-3 letra-contenido-2">
                          <CRow>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="email">Email</CFormLabel>
                              <CFormInput className='letra-contenido-modal-input' size="sm" id="email" placeholder="Ingrese nombre" value={email} onChange={(e) => setEmail(e.target.value)} disabled readOnly />
                            </CCol>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="phone">Telèfono</CFormLabel>
                              <CFormInput className='letra-contenido-modal-input' size="sm" id="phone" placeholder="Ingrese apellido" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </CCol>
                          </CRow>
                        </div>

                        <div className="mb-3 letra-contenido-2">
                          <CRow>
                            <CCol xs={12}>
                              <CRow>
                                <CCol xs={12}>
                                  {/* <CFormLabel className='titulo-table-padding' htmlFor="anchoCarril">Contraseña</CFormLabel> */}
                                  <CFormSwitch className='titulo-table-padding-password' label="Contraseña" id="formSwitchCheckDefaultNormal" style={{ '--form-switch-checked-color': 'white' }} onChange={(e) => {
                                    // Actualiza el estado del formulario

                                    setChangePassword(e.target.value);

                                    // Elimina el error si se corrige el campo
                                    if (e.target.value) {
                                      setErrors(prevErrors => ({
                                        ...prevErrors,
                                        [`actualPassword`]: undefined,
                                        [`password`]: undefined,
                                        [`confirmarPassword`]: undefined
                                      }));
                                    }
                                  }} />
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol xs={6}>
                                  <CFormLabel htmlFor="actualPassword">Contraseña actual</CFormLabel>
                                  <CFormInput disabled={!changePassword} className='letra-contenido-modal-input' type='password' size="sm" id="actualPassword" placeholder="Ingrese contraseña" value={actualPassword} onChange={(e) => {
                                    // Actualiza el estado del formulario

                                    setActualPassword(e.target.value);

                                    // Elimina el error si se corrige el campo
                                    if (e.target.value) {
                                      setErrors(prevErrors => ({
                                        ...prevErrors,
                                        [`actualPassword`]: undefined
                                      }));
                                    }
                                  }} />
                                  {errors.actualPassword && <div className="text-danger">{errors.actualPassword}</div>} {/* Mostrar error */}
                                </CCol>
                                <CCol xs={6}>
                                  <CFormLabel htmlFor="password">Nueva contraseña</CFormLabel>
                                  <CFormInput disabled={!changePassword} className='letra-contenido-modal-input' type='password' size="sm" id="password" placeholder="Ingrese nueva contraseña" value={password} onChange={(e) => {
                                    // Actualiza el estado del formulario

                                    setPassword(e.target.value);

                                    // Elimina el error si se corrige el campo
                                    if (e.target.value) {
                                      setErrors(prevErrors => ({
                                        ...prevErrors,
                                        [`password`]: undefined
                                      }));
                                    }
                                  }} />
                                  {errors.password && <div className="text-danger">{errors.password}</div>} {/* Mostrar error */}
                                </CCol>

                              </CRow>
                            </CCol>

                          </CRow>

                        </div>
                        <div className="mb-3 letra-contenido-2">
                          <CRow>
                            <CCol xs={6}>
                              <CFormLabel htmlFor="confirmarPassword">Confirmación de nueva contraseña</CFormLabel>
                              <CFormInput disabled={!changePassword} className='letra-contenido-modal-input' type='password' size="sm" id="confirmarPassword" placeholder="Confirmar nueva contraseña" value={confirmarPassword} onChange={(e) => {
                                // Actualiza el estado del formulario

                                setConfirmarPassword(e.target.value);

                                // Elimina el error si se corrige el campo
                                if (e.target.value) {
                                  setErrors(prevErrors => ({
                                    ...prevErrors,
                                    [`confirmarPassword`]: undefined
                                  }));
                                }
                              }} />
                              {errors.confirmarPassword && <div className="text-danger">{errors.confirmarPassword}</div>} {/* Mostrar error */}
                            </CCol>
                          </CRow>
                        </div>
                      </CCol>
                      <CCol xs='4' className='quitar-padding'>
                        <CCard className='container-upload-file'>
                          {/* <div className="image-uploader-form-user">
                    {selectedImage ? (
                      <img src={URL.createObjectURL(selectedImage)} alt="Selected Image" className="avatar2" />
                    ) : (
                      <img src='https://cdn-icons-png.flaticon.com/512/4980/4980606.png' alt="Selected Image" className="avatar2" />
                    )}
                    <CFormInput  
                    type="file" 
                    accept="image/*" 
                    onChange={handleImageChange} 
                    className="file-input" 
                    ref={fileInputRef}
                    />
                    <span className='letra-contenido'>Arrastra aquí tus imagenes</span>
                    <span className='letra-contenido'>Formatos JPG, PNG</span>
                    <CButton className="btn-form-user" onClick={handleSelectImage} ><CIcon className='icon-button-upload' icon={cilArrowTop} style={{'--ci-primary-color': 'black'}} />Seleccionar imagen</CButton>
                  </div> */}
                          <div className="image-uploader-avatar3">
                            {selectedImage ? (
                              <img src={URL.createObjectURL(selectedImage)} alt="Selected Image" className="avatar3" />
                            ) : (
                              <img src={imagenPerfil} alt="Selected Image" className="avatar3" />
                            )}
                            <CFormInput
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              className="file-input"
                              ref={fileInputRef}
                            />
                            {/* <span> <strong>{storedName}</strong></span>
                    <span>{storedEmail}</span> */}
                            {/* <CButton className="btn-search-list" onClick={handleSelectImage} ><CIcon className='icon-button-upload' icon={cilArrowTop} style={{'--ci-primary-color': 'black'}} />Seleccionar imagen</CButton> */}
                          </div>
                        </CCard>
                      </CCol>
                    </CRow>
                  </div>
                  {/* <div>
          <CFormSwitch label="Cambiar la contraseña" id="formSwitchCheckDefaultNormal" onChange={(e) => setChangePassword(e.target.checked)} />
          </div> */}
                </CForm>





              </CCardBody>
            </CCard>

          </CCol>
        </CCol>
      </CRow>

      <footer className='footer-form-video-estilo'>
        <CRow>
          <CCol xs='12'>
            <CRow>
              <CCol xs='8' className='footer-text-save-leave'></CCol>
              <CCol xs='1'>
                <div className="">
                  <CButton href='/profile/user' className="px-8 btn-salir-footer" type="submit">Salir</CButton>
                </div>
              </CCol>
              {/* <CCol xs='1' className='quitar-padding'>
          
    </CCol> */}
              <CCol xs='1' className='padding-left-footer-next'>
                <div className="">
                  <CButton onClick={handleSubmit} className="px-8 btn-next-footer" type="submit">Guardar</CButton>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </footer>
      {showSuccessToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-exito"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Editado usuario correctamente.</CToastBody>
            <CToastClose onClick={handleCloseToast} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}

      {showErrorToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-error"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Ha ocurrido un Error.</CToastBody>
            <CToastClose onClick={handleCloseToastError} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}
      {isLoading && ( // Mostrar el spinner solo si isLoading es true
        <div className="loading-overlay">
          <CSpinner color="primary" />
        </div>
      )}
    </>
  );
}

export default FormUser;