import React, { useState } from 'react';
import Header from '../Header/Header';
import AppSidebar2 from '../AppSidebar2';
import NewProject from './NewProject';

function NewProjectComponet() {
  const [profile, setProfile] = useState(localStorage.getItem('name') || '');

  return (
    <div>
      <AppSidebar2 />
      <div className="wrapper d-flex flex-column min-vh-100">
        <Header profile={profile} /> 
        <div className="body flex-grow-1">
          <NewProject /> 
        </div>
      </div>
    </div>
  );
}

export default NewProjectComponet;