import React, { useState, useContext } from 'react';
import AppSidebar from '../AppSidebar';
import DetailIndex from '../Detail/DetailIndex';

import {
  CButton,
} from '@coreui/react'

function DetailProject({ profile, setProfile }) {

  return (
      <div>
        <AppSidebar setProfile={setProfile} />
        <div className="wrapper d-flex flex-column min-vh-100">
          {/* <Header profile={profile} />  */}
          <div className="body flex-grow-1">
            <DetailIndex profile={profile} setProfile={setProfile} /> 
          </div>
        </div>
      </div>
  );
}

export default DetailProject;