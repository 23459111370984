import React, { useState, useEffect } from 'react';

const InactivityHandler = ({ children }) => {
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const inactivityTimeout = 1000 * 60 * 30; // 2 minutos de inactividad

  useEffect(() => {
    // const intervalId = setInterval(() => {
    //   const currentTime = Date.now();
    //   if (currentTime - lastActivityTime >= inactivityTimeout) {
    //     handleLogout();
    //   }
    // }, 1000);

    // Añadir el listener para el evento 'beforeunload'
    window.addEventListener('beforeunload', handleLogout);

    return () => {
      // clearInterval(intervalId);
      // Eliminar el listener para 'beforeunload'
      window.removeEventListener('beforeunload', handleLogout); 
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('accessToken'); // Elimina el token del localStorage
    localStorage.removeItem('refreshToken'); // Elimina el refresh token del localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('total');
    localStorage.removeItem('id');
    // No es necesario redirigir, ya que la página se va a cerrar
    // window.location = '/'; // Redirige a la página de inicio de sesión 
  };

  // const handleActivity = () => {
  //   setLastActivityTime(Date.now()); 
  // };

  return (
    // <div onMouseOver={handleActivity} onFocus={handleActivity}> 
    //   {children} 
    // </div>
    <div> 
      {children} 
    </div>
  );
};

export default InactivityHandler;