import React, { useState } from 'react'
import validator from 'validator';
import { Link, useNavigate } from 'react-router-dom'
import '../../scss/style.scss'
import PropTypes from 'prop-types';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CCardImage,
  CAlert,
  CFormLabel,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser, cilLowVision } from '@coreui/icons'
import logo from '../../assets/images/ACA-Marca-RGB.ai_-1536x560.png'
import rutaNacional from '../../assets/images/ruta-nacional4.png'
import hide from '../../assets/images/hide.png'
import view from '../../assets/images/view.png'

async function senData(credentials) {
//   return fetch(process.env.REACT_APP_URL_API+'/api/auth/login', {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/login`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

const Login = ({ setToken, setProfile }) => {

  const navigate = useNavigate(); // Hook para navegar
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const { token, setToken } = useToken();

  // const handleLogin = async (userData) => {
  //     setToken(userData); // Llama a la función setToken para guardar el token
  //   };



  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = async e => {
    setIsLoading(true);
    try {
      console.log('Error al enviar los datos:');
      console.log(email);
      e.preventDefault();
      if (!email) {
        setError('Ingresá tu correo electrónico');
        setShow(true);
        return false;
      } else if (!validator.isEmail(email)) {
        setError('Ingresá un correo electrónico válido');
        setShow(true);
        return false;
      } else if (!password) {
        setError('Ingresá tu contraseña');
        setShow(true);
        return false;
      } else {
        console.log('datos:');
        const data = await senData({
          email,
          password
        });
        console.log(data);
        if (data.error) {

          setError(data.error);
          setShow(true);
          return false;
        }
        // handleLogin(data);
        setToken(data);
        setProfile(data.name);
        navigate('/dashboard'); // Redirige a '/home'
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setError("Error al iniciar sesión. Por favor, inténtalo de nuevo.");
      setShow(true);
    } finally {
      setIsLoading(false); // Ocultar el spinner al finalizar la carga
      navigate('/login'); // Redirige a '/home'
    }
  }

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CCard className='padding-car-login-externo delete-border-color'>
          <CRow className="justify-content-center">

            <CCol md={6} className='quitar-padding'>
              <CCardGroup className='delete-border-color'>
                <CCard className="p-4 delete-border-color">
                  <CCardBody >
                    <CCard className="image-container delete-border-color">
                      <CCardImage className="image-container-img" orientation="top" src={logo} width={50} />
                      <CAlert className='alert-acya' color="danger" visible={show}>
                        {error}
                      </CAlert>
                    </CCard>
                    <CRow className="justify-content-end">
                      <CForm onSubmit={handleSubmit}>
                        <CInputGroup className="mb-3">
                          <CCol xs={2}></CCol>
                          <CCol xs={8}>
                            <CFormLabel className='letra-login' htmlFor="email"><strong>Email</strong></CFormLabel>
                            <CFormInput
                              size="sm"
                              id="email"
                              placeholder="Ingrese su email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </CCol>
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CCol xs={2}></CCol>
                          <CCol xs={8}>
                            <CFormLabel className='letra-login' htmlFor="password"><strong>Contraseña</strong></CFormLabel>
                            <div style={{ position: 'relative' }}>
                              <CFormInput
                                size="sm"
                                type={isVisible ? "text" : "password"}
                                id="password"
                                placeholder="Ingrese su contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <div
                                className='quitar-padding'
                                style={{
                                  position: 'absolute',
                                  top: '40%',
                                  right: '10px',
                                  transform: 'translateY(-50%)',
                                  zIndex: 1, // Asegúrate de que esté por encima del input
                                }}
                              >
                                <CButton onClick={handleClick} className="btn-ocultar-password quitar-padding">
                                  {isVisible ? <img src={hide} alt="Mostrar/Ocultar Contraseña" style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={view} alt="Mostrar/Ocultar Contraseña" style={{ width: '20px', height: '20px' }} />
                                  }
                                </CButton>
                              </div>
                            </div>
                          </CCol>
                        </CInputGroup>
                        <CRow>
                          <CCol xs={1}></CCol>
                          <CCol xs={10} className="text-right-aling">
                            <CButton color="link" className="px-4 margin-button-forgot-pass">
                              {/* ¿Olvidaste tu contraseña? */}
                            </CButton>
                          </CCol>
                        </CRow>

                        <CRow className="row-center-login-btn">

                          <CCol xs={8}>
                            <CButton className="px-4 btn-primary-login" type="submit" style={{ 'width': '100%' }}>
                              Ingresar
                            </CButton>

                            {isLoading && ( // Mostrar el spinner solo si isLoading es true
                               <div className="loading-overlay">
                              <CSpinner color="primary" />
                              </div>
                            )}
                          </CCol>
                        </CRow>
                      </CForm>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
            <CCol md={6} className='quitar-padding'>
              <CCardGroup className=''>
                <CCard className=''>

                  <CCardImage className="" orientation="top" src={rutaNacional} width={100} />

                </CCard>
              </CCardGroup>
            </CCol>

          </CRow>
        </CCard>
      </CContainer>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired

}

export default Login

