import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import useToken from './components/App/useToken';

export default function AppRoutes() {
  const { token } = useToken(); 

  return (
    <Routes> 
      {token ? (
        
          <Route path="/dashboard" element={<Dashboard />} /> 
          
      ) : (
        <>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} /> 
        </>
      )}
    </Routes>
  );
}