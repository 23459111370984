import React, { useState, useEffect, useRef } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'; // Importa Link
import CircleStatus from '../CircleStatus/CircleStatus'; // Importa el componente

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CModalContent,
  CTooltip,
  CToast,
  CToastBody,
  CToastClose,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilCursor,
  cilOptions,
  cilReload,
} from '@coreui/icons'

import circleRojo from '../../assets/images/circle-rojo.png';
import circleAmarillo from '../../assets/images/circle-amarillo.png';
import circleVerde from '../../assets/images/circle-verde.png';
import circleGris from '../../assets/images/circle-gris.png';
import circle from '../../assets/images/circle.png';
// import WidgetsBrand from '../widgets/WidgetsBrand'
// import WidgetsDropdown from '../widgets/WidgetsDropdown'
// import  grupo5774  from '../../assets/images/Grupo-5774.png'
import reset from '../../assets/images/reset.png'
import stop from '../../assets/images/stop.png'
import init from '../../assets/images/init.png'
import play from '../../assets/images/play.png'

const List = ({ profile, setProfile }) => {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');
  const navigate = useNavigate();
  const [nombreProyecto, setNombreProyecto] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [estado, setEstado] = useState(null);
  const [visible, setVisible] = useState(false)
  const [visible2, setVisible2] = useState(false)
  // const [scrollOffset, setScrollOffset] = useState(0);
  // const tableRef = useRef(null); // Referencia para la tabla
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemId, setItemId] = useState(null); // Estado para almacenar el ID del elemento a eliminar
  const [dataVideo, setDataVideo] = useState(null);
  const [statusSalad, setStatusSalad] = useState('none');
  const [idProject, setIdProject] = useState(null);
  const [nameProject, setNameProject] = useState(null);
  const [mensajeSalad, setMensajeSalad] = useState('');
  const [mensajeSaladSuccess, setMensajeSaladSuccess] = useState('');
  const [mensaje, setMensaje] = useState('');

  // Función para obtener datos del servidor
  function truncateText(text, maxLength, truncationText = "...") {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + truncationText;
    }
    return text;
  }

  async function fetchData() {
    setIsLoading(true);
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jwt/list/project`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
        } else {
          // Otros errores
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
      }
      const jsonData = await response.json();
      localStorage.setItem('data', JSON.stringify(jsonData));
      // console.log(jsonData)

    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Llama a fetchData cuando el componente se monta
  useEffect(() => {
    fetchData();
    // console.log(localStorage.getItem('token'))
  }, []);

  const deleteProject = async (id) => {
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/delete/project/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        },
        method: 'DELETE',
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
        } else {
          // Otros errores
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
      }
      // Maneja la respuesta (por ejemplo, muestra un mensaje de éxito)
      if (response.ok) {
        console.log("Status 200: Borrado Correctamente");
        window.location.href = '/'; // Ajusta la ruta si es necesario 
      } else {
        // ...
      }
    } catch (error) {
      console.error('Error eliminando proyecto:', error);
    }
  };

  const handleFilter = () => {
    // Restablece los filtros a null
    setNombreProyecto('');
    setEstado(null);
    setPeriodo(null);
    document.getElementById("estadoProyecto").getElementsByTagName('option')[0].selected = 'selected'
    document.getElementById("periodo").getElementsByTagName('option')[0].selected = 'selected'
  };

  const handleDelete = (id) => {
    setItemId(id); // Almacena el ID antes de abrir el modal
    setVisible(true);
  };

  const handleConfirmDelete = () => {
    // Llama a tu función de API con el itemId
    console.log('itemId1: ', itemId)
    deleteProject(itemId);
    // navigate('/');
    console.log('itemId: ', itemId)

    // Cierra el modal
    setVisible(false);
  };

  const handleProcess = async (id, nombre) => {
    setIsLoading(true);
    try {
      setNameProject(nombre)
      setIdProject(id)
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/video/get-data-video/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
        } else {
          // Otros errores
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
      }
      const jsonData = await response.json();
      localStorage.setItem('data_video', JSON.stringify(jsonData.data_video));
      setDataVideo(jsonData)
      // console.error(JSON.parse(localStorage.getItem('data_video')));

    } catch (error) {
      setError(error);
      console.error('Error al obtener datos:', error);
    } finally {
      setIsLoading(false);
    }
    setItemId(id); // Almacena el ID antes de abrir el modal
    setVisible2(true);
    handleReloadStatus()
  }


  const handleReloadStatus = async () => {
    setIsLoading(true);
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api-upload-videos-process`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
        } else {
          // Otros errores
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
      }
      const jsonData = await response.json();
      // localStorage.setItem('data', JSON.stringify(jsonData));
      setStatusSalad(jsonData.status)
      console.error(statusSalad.status);

    } catch (error) {
      setError(error);
      console.error('Error al obtener datos:', error);
    } finally {
      setMensajeSaladSuccess('')
      setMensajeSalad('');
      setIsLoading(false);
    }
    // setItemId(id); // Almacena el ID antes de abrir el modal
    // setVisible2(true);
  }

  const handleStartSalad = async () => {
    setIsLoading(true);
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api-start-videos-process`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
        } else {
          // Otros errores
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
      }
      const jsonData = await response.json();
      // localStorage.setItem('data', JSON.stringify(jsonData));
      // setStatusSalad(jsonData.status)
      // console.error(statusSalad.status);

    } catch (error) {
      setError(error);
      console.error('Error al obtener datos:', error);
    } finally {
      setMensajeSaladSuccess('')
      setMensajeSalad('');
      handleReloadStatus()
      setIsLoading(false);
    }
    // setItemId(id); // Almacena el ID antes de abrir el modal
    // setVisible2(true);
  }

  const handleStopSalad = async () => {
    setIsLoading(true);
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api-stop-videos-process`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
        } else {
          // Otros errores
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
      }
      const jsonData = await response.json();
      // localStorage.setItem('data', JSON.stringify(jsonData));
      // setStatusSalad(jsonData.status)
      // console.error(statusSalad.status);

    } catch (error) {
      setError(error);
      console.error('Error al obtener datos:', error);
    } finally {
      setMensajeSaladSuccess('')
      setMensajeSalad('');
      handleReloadStatus()
      setIsLoading(false);
    }
    // setItemId(id); // Almacena el ID antes de abrir el modal
    // setVisible2(true);
  }

  const handleSendSalad = async () => {
    setIsLoading(true);
    setMensajeSaladSuccess('')
    setMensajeSalad('');
    setMensaje('Espere...')
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api-send-videos-process/${idProject}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      // if (!response.ok) {
      //   if (response.status === 401) { 
      //     // Token inválido o expirado
      //     console.error("Error 401: No autorizado");
      //     // Redirige al inicio de sesión, limpia el token, etc.
      //     localStorage.removeItem('token');
      //     window.location.href = '/login'; // Ajusta la ruta si es necesario 
      //   } 
      //   if (response.status === 404) { 
      //     setMensaje('')
      //     setMensajeSaladSuccess('')
      //     setMensajeSalad('Error. ' + response.status + ' Intente mas tarde');
      //   }
      //   if (response.status === 503) { 
      //     setMensaje('')
      //     setMensajeSaladSuccess('')
      //     setMensajeSalad('Error. ' + response.status + ' Intente mas tarde');
      //   }
      //   if (response.status === 502) { 
      //     setMensaje('')
      //     setMensajeSaladSuccess('')
      //     setMensajeSalad('Error. ' + response.status + ' Intente mas tarde');
      //   }
      //   if (response.status === 500) { 
      //     setMensaje('')
      //     setMensajeSaladSuccess('')
      //     setMensajeSalad('Error. ' + response.status + ' Intente de nuevo');
      //   }
      //   if (response.status === 524) { 
      //     setMensaje('')
      //     setMensajeSalad('');
      //     setMensajeSaladSuccess(('Exito. 200 Procesando...'))
      //   } 
      // }
      if (!response.ok) {
        if (response.status === 401) {
          // Token inválido o expirado
          console.error("Error 401: No autorizado");
          // Redirige al inicio de sesión, limpia el token, etc.
          localStorage.removeItem('token');
          window.location.href = '/login'; // Ajusta la ruta si es necesario 
        } else if (response.status === 524) {
          // Maneja el error 524
          setMensaje('');
          setMensajeSalad('');
          setMensajeSaladSuccess(('Exito. 200 Procesando...'));
        } else {
          // Maneja otros errores
          let errorMessage = `Error desconocido: ${response.status}`;

          // Puedes personalizar el mensaje dependiendo del error
          if ([404, 503, 502, 500].includes(response.status)) {
            errorMessage = `Error: ${response.status}. Reinicie la IA o intente más tarde`;
          }
          setMensaje('');
          setMensajeSaladSuccess('');
          setMensajeSalad(errorMessage);
        }
      }
      if (response.ok) {
        if (response.status === 200) {
          setMensaje('')
          setMensajeSalad('');
          setMensajeSaladSuccess(('Exito. ' + response.status + ' Procesando...'))
        }
      }

      const jsonData = await response.json();
      // localStorage.setItem('data', JSON.stringify(jsonData));
      // setStatusSalad(jsonData.status)
      // console.error(statusSalad.status);
    } catch (error) {
      setError(error);
      console.error('Error al obtener datos:', error);
    } finally {
      setIsLoading(false);
      setMensaje('')
    }
    // setItemId(id); // Almacena el ID antes de abrir el modal
    // setVisible2(true);
  }

  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs>
          <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
            <CIcon icon={cilCursor} size="xxl" className="height-auto icon-background-orange" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Proyectos</strong></h5>
          </CCol>
          <CCard className="mb-4">
            {/* <CCardHeader>Todos los Proyectos</CCardHeader> */}
            <CCardBody className='contenedor-table-list-titulo'>
              <CCard className='container-card-list'>

                <div className="mb-4 letra-contenido-2">
                  <CRow className='row-list-field'>
                    <CCol xs={3} className='solid-col-list'>
                      <CFormLabel htmlFor="nombreProyecto">ID / Nombre de proyecto</CFormLabel>
                      <CFormInput className="input-with-icon form-select-list-estado letra-contenido-modal-input" id="nombreProyecto" placeholder="Ingrese nombre o ID" value={nombreProyecto} onChange={(e) => setNombreProyecto(e.target.value)} />
                    </CCol>

                    <CCol xs={3} className='solid-col-list'>
                      <CFormSelect className='form-select-list-estado-2 fuente-letra-gris letra-contenido-modal-input'
                        id='estadoProyecto'
                        label="Estado de proyecto"
                        value={estado} // Establece el valor actual del estado
                        onChange={(e) => setEstado(e.target.value)}>
                        <option className='fuente-letra-oscura' >Seleccione estado</option>
                        <option className='fuente-letra-oscura' value="creado">Creado</option>
                        <option className='fuente-letra-oscura' value="procesando">Procesando</option>
                        <option className='fuente-letra-oscura' value="finalizado">Finalizado</option>
                      </CFormSelect>
                    </CCol>
                    <CCol xs={4} className='solid-col-list'>
                      <CRow className=''>
                        <CCol xs={8} className='solid-col-list-2'>
                          <CFormSelect className='form-select-list-estado-3 fuente-letra-gris letra-contenido-modal-input'
                            id='periodo'
                            label="Periodo"
                            value={periodo} // Establece el valor actual del estado
                            onChange={(e) => setPeriodo(e.target.value)}>
                            <option className='fuente-letra-oscura' >Seleccione periodo</option>
                            <option className='fuente-letra-oscura' value="7">Últimos 7 dias</option>
                            <option className='fuente-letra-oscura' value="15">Últimos 15 dias</option>
                            <option className='fuente-letra-oscura' value="30">Últimos 30 dias</option>
                            <option className='fuente-letra-oscura' value="90">Últimos 90 dias</option>
                          </CFormSelect>
                        </CCol>
                        <CCol xs={4} className='solid-col-list-3'>
                          <CButton className="btn-search-list form-select-list-estado" type="submit" style={{ display: 'flex', alignItems: 'center' }}><CIcon icon={cilReload} style={{ '--ci-primary-color': 'black' }} onClick={handleFilter} /></CButton>
                          {/* <CButton className="btn-search-list form-select-list-estado" type="submit" style={{ display: 'flex', alignItems: 'center' }}> <img src={grupo5774} alt="Descripción de la imagen" className="nav-icon width-img-search" onClick={handleFilter} /></CButton> */}
                        </CCol>
                      </CRow>
                    </CCol>


                    <CCol xs={2} className='solid-col-list-4'>
                      <CRow className='btn-list'>
                        <CCol xs={12}>
                          <CButton href="/new-project/form" className="px-8 btn-primary-orange" type="submit">Nuevo Proyecto</CButton>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </div>
              </CCard>
            </CCardBody>
            <CCardBody className='contenedor-table-list container-tabla letra-contenido-2'>

              <div className='contenedor-scroll-tabla-list' style={{ overflowy: 'auto', height: '370px' }}>
                {/* <div 
          ref={tableRef} 
          style={{ 
            width: '100', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        > */}
                <CTable striped small align="middle" className="mb-0 ctable-list" style={{ width: '100%' }}>
                  <CTableHead className='letra-contenido-2 sticky-header'>
                    <CTableRow>
                      <CTableHeaderCell className='letra-contenido-2 text-center' style={{ width: '11vh' }}>
                        ID
                      </CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2 fgh' >Nombre</CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2 fgh' >Fecha creacíon</CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2 fgh' >Estado</CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2 fgh' >Fecha inicio</CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2 fgh' >Fecha fin</CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2 fgh' >Cliente</CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2 fgh' >Operador</CTableHeaderCell>
                      <CTableHeaderCell className='letra-contenido-2' >Descripcíon</CTableHeaderCell>
                      <CTableHeaderCell ></CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody className='letra-contenido-3'>
                    {isLoading ? (
                      <div>Cargando datos... </div>
                      // <div> <br></br><CButton onClick={fetchData} className="px-8 btn-primary-orange" type="submit">Cargar Datos</CButton>
                      // </div> // Muestra un indicador de carga
                    ) : (
                      // Renderiza la tabla con los datos de localStorage
                      localStorage.getItem('data') && (
                        localStorage.getItem('data') !== null &&
                        localStorage.getItem('data') !== '' &&
                        Array.isArray(JSON.parse(localStorage.getItem('data'))) && // Comprueba si es un array
                        JSON.parse(localStorage.getItem('data'))
                          .filter((item) => {
                            // Calcula la fecha límite en base al periodo
                            const currentDate = new Date();
                            const limitDate = new Date(currentDate.getTime() - periodo * 24 * 60 * 60 * 1000);
                            const fechaInicio = new Date(item.fecha_inicio);
                            return (
                              (nombreProyecto === null || item.nombre.toLowerCase().includes(nombreProyecto.toLowerCase())) &&
                              (estado === null || item.estado === estado) &&
                              (periodo === null || fechaInicio >= limitDate) // Compara con fecha_inicio
                            );
                          })
                          .map((item, index) => (
                            <CTableRow v-for="item in tableItems" key={index}>
                              <CTableDataCell className="letra-contenido-3 my-tall-cell text-center">
                                {item.id}
                              </CTableDataCell>
                              <CTableDataCell className='letra-contenido-3 my-tall-cell truncate-cell'>
                                <div


                                  className="truncate-text"
                                  title={truncateText(item.nombre, 20) === item.nombre ? '' : item.nombre}
                                >
                                  <img
                                    src={item.video_procesar === 1 ? circleRojo : circleGris} // Ajusta la ruta según tu estructura
                                    alt="Selected Image"
                                    className="mr-2"
                                    style={{ width: '11px', height: '11px', objectFit: 'contain', paddingRight: '5px' }}
                                  />
                                  {/* Si se trunca, muestra el tooltip con el nombre completo */}
                                  {truncateText(item.nombre, 20)}
                                </div>
                              </CTableDataCell>
                              <CTableDataCell className="letra-contenido-3 my-tall-cell">
                                <div>{item.fecha_creacion}</div>
                                {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                              </CTableDataCell>
                              <CTableDataCell className='letra-contenido-3 my-tall-cell'>
                                <div className="d-flex justify-content-between text-nowrap">
                                  <div>{item.estado}</div>
                                  <div className="ms-3">
                                    {/* <small className="text-body-secondary">{item.usage.period}</small> */}
                                  </div>
                                </div>
                                {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                              </CTableDataCell>
                              <CTableDataCell className="letra-contenido-3 my-tall-cell">
                                <div>{item.fecha_inicio}</div>
                              </CTableDataCell>
                              <CTableDataCell className="letra-contenido-3 my-tall-cell">
                                <div>{item.fecha_fin}</div>
                                {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                              </CTableDataCell>
                              <CTableDataCell className="letra-contenido-3 my-tall-cell">
                                <div
                                  className="truncate-text"
                                  title={truncateText(item.cliente, 10) === item.cliente ? '' : item.cliente}
                                >
                                  {/* Si se trunca, muestra el tooltip con el nombre completo */}
                                  {truncateText(item.cliente, 10)}
                                </div>
                                {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                              </CTableDataCell>
                              <CTableDataCell className="letra-contenido-3 my-tall-cell">
                                <div
                                  className="truncate-text"
                                  title={truncateText(item.operador, 10) === item.operador ? '' : item.operador}
                                >
                                  {/* Si se trunca, muestra el tooltip con el nombre completo */}
                                  {truncateText(item.operador, 10)}
                                </div>
                                {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                              </CTableDataCell>
                              <CTableDataCell className="letra-contenido-3 my-tall-cell">
                                <div
                                  className="truncate-text"
                                  title={truncateText(item.descripcion, 20) === item.descripcion ? '' : item.descripcion}
                                >
                                  {/* Si se trunca, muestra el tooltip con el nombre completo */}
                                  {truncateText(item.descripcion, 20)}
                                </div>
                                {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                              </CTableDataCell>
                              <CTableDataCell>
                                <CDropdown variant="btn-group">
                                  <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{ '--ci-primary-color': 'black' }} /></CDropdownToggle>
                                  <CDropdownMenu className='dropdown-menu-list'>
                                    <CDropdownItem href={`/detail-project/${item.id}`}>Ver detalle</CDropdownItem>
                                    <CDropdownItem className="cursor-pointer" onClick={() => handleProcess(item.id, item.nombre)}>Procesar</CDropdownItem>
                                    <CDropdownItem href={`/project/form-edit/${item.id}`}>Editar</CDropdownItem>
                                    <CDropdownItem className="cursor-pointer" onClick={() => handleDelete(item.id)}>Eliminar</CDropdownItem>
                                  </CDropdownMenu>
                                </CDropdown>
                              </CTableDataCell>
                            </CTableRow>
                          ))

                      )
                    )}
                  </CTableBody>
                </CTable>
              </div>
              {/* </div> */}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CModal
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
        size='sm'
      >
        <CModalContent style={{ 'width': '400px' }}>
          <CModalHeader style={{ '--cui-modal-header-border-color': 'white' }}>
            <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Eliminar Proyecto</CModalTitle>
          </CModalHeader>
          <CModalBody className='letra-contenido-modal'>
            Estas por eliminar el proyecto de manera definitiva, recuerda que una vez eliminado no podras recuperar esa información.
            ¿Estas seguro que deses eliminarlo?
          </CModalBody>
          <CModalFooter style={{ '--cui-modal-footer-border-color': 'white' }}>
            <CButton className='btn-salir-footer' onClick={() => setVisible(false)}>
              Cancelar
            </CButton>
            <CButton className='btn-next-footer' onClick={handleConfirmDelete}>Aceptar</CButton>
          </CModalFooter>
        </CModalContent>
      </CModal>

      <CModal
        alignment="center"
        scrollable
        visible={visible2}
        onClose={() => setVisible2(false)}
        aria-labelledby="VerticallyCenteredExample"
        size='lg'
      >
        <CModalContent style={{ 'width': '800px', height: '600px' }}>
          <CModalHeader style={{ '--cui-modal-header-border-color': 'white' }}>
            {/* <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Procesar  Videos del Proyecto : {nameProject}</CModalTitle> */}
            <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Proyecto Nro {idProject}. {nameProject}</CModalTitle>
          </CModalHeader>
          <br></br>
          <div className="d-flex align-items-center">
            {/* Contenedor para el texto (Flexbox) */}
            <div className="flex-grow-1 texto-status-modal-procesar"> {/* Ajusta el tamaño del texto */}
              <strong className='letra-contenido-4'>Estatus: </strong>
              <CircleStatus className="letra-contenido-4" statusSalad={statusSalad} />
              <CTooltip
                content="Resfrescar estatus"
                placement="top"
              >
                <CButton onClick={handleReloadStatus} className="btn-modal-process">


                  <img src={reset} alt="Mostrar/Ocultar Contraseña" style={{ width: '25px', height: '25px' }} />

                </CButton>
              </CTooltip>
            </div>
            <span className='letra-contenido-5'>{mensaje}</span>
            <span className='letra-contenido-7'>{mensajeSaladSuccess}</span>
            <span className='letra-contenido-6'>{mensajeSalad}</span>
            {/* Contenedor para los botones (Flexbox) */}
            <div className="d-flex btns-modal-procesar">
              {/* <CButton className="btn-model-procesar" onClick={handleStopSalad}>
                  <span className="padding-separar-boton-descargar">
                    <CIcon icon={cilPowerStandby} size="m" />
                  </span>
                </CButton>
                <CButton className="btn-model-procesar" onClick={handleStartSalad}>
                  <span className="padding-separar-boton-descargar">
                    <CIcon icon={cilMediaPlay} size="m" />
                  </span>
                </CButton>
                <CButton className="btn-model-procesar" onClick={handleSendSalad}>
                  <span className="padding-separar-boton-descargar">
                    <CIcon icon={cilMediaPlay} size="m" />
                  </span>
                </CButton>
                <CButton className="btn-model-procesar" onClick={handleReloadStatus}>
                  <span className="padding-separar-boton-descargar">
                    <CIcon icon={cilReload} size="m" />
                  </span>
                </CButton> */}
              <CTooltip
                content="Detener la IA"
                placement="top"
              >
                <CButton onClick={handleStopSalad} className="btn-modal-process no-padding-x">


                  <img src={stop} alt="Mostrar/Ocultar Contraseña" style={{ width: '25px', height: '25px' }} />

                </CButton>
              </CTooltip>
              <CTooltip
                content="Iniciar la IA"
                placement="top"
              >
                <CButton onClick={handleStartSalad} className="btn-modal-process no-padding-x">


                  <img src={init} alt="Mostrar/Ocultar Contraseña" style={{ width: '25px', height: '25px' }} />

                </CButton>
              </CTooltip>
              {statusSalad === 'running' ? (
        <CTooltip
        content="Iniciar del procesamiento"
        placement="top"
      >
        <CButton onClick={handleSendSalad} className="btn-modal-process no-padding-x">


          <img src={play} alt="Mostrar/Ocultar Contraseña" style={{ width: '25px', height: '25px' }} />

        </CButton>
      </CTooltip>
      ) : (
        <CTooltip
                content="Iniciar del procesamiento"
                placement="top"
              >
                <CButton disabled onClick={handleSendSalad} className="btn-modal-process no-padding-x">


                  <img src={play} alt="Mostrar/Ocultar Contraseña" style={{ width: '25px', height: '25px' }} />

                </CButton>
              </CTooltip>
      )}
              {/* <CTooltip
                content="Iniciar del procesamiento"
                placement="top"
              >
                <CButton onClick={handleSendSalad} className="btn-modal-process no-padding-x">


                  <img src={play} alt="Mostrar/Ocultar Contraseña" style={{ width: '25px', height: '25px' }} />

                </CButton>
              </CTooltip> */}


            </div>
          </div>
          <br></br>
          <CModalBody className='letra-contenido-modal'>

            {/* stopped */}
            {/* deploying */}
            {/* running */}

            <CTable>
              <CTableHead className='letra-contenido-4 sticky-header-modal'>
                <CTableRow>
                  <CTableHeaderCell className="letra-contenido-4" scope="col">Id</CTableHeaderCell>
                  <CTableHeaderCell className="letra-contenido-4" scope="col">Nombre video</CTableHeaderCell>
                  <CTableHeaderCell className="letra-contenido-4" scope="col">Camara</CTableHeaderCell>
                  <CTableHeaderCell className="letra-contenido-4" scope="col">Status</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {localStorage.getItem('data_video') &&
                  JSON.parse(localStorage.getItem('data_video')).map((item, index) => (
                    <CTableRow>
                      <CTableHeaderCell className="letra-contenido-5" scope="row">{item.id}</CTableHeaderCell>
                      <CTableDataCell className="letra-contenido-5">
                        <div
                          className="truncate-text"
                          title={truncateText(item.nombre_archivo, 70) === item.nombre_archivo ? '' : item.nombre_archivo}
                        >
                          {/* Si se trunca, muestra el tooltip con el nombre completo */}
                          {truncateText(item.nombre_archivo, 70)}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="letra-contenido-5">{item.tipo_camara}</CTableDataCell>
                      <CTableDataCell className="letra-contenido-5">{item.descripcion}</CTableDataCell>
                    </CTableRow>
                  ))
                }
              </CTableBody>
            </CTable>
          </CModalBody>
          <CModalFooter style={{ '--cui-modal-footer-border-color': 'white' }}>
            {/* <CButton className='btn-salir-footer' onClick={() => setVisible2(false)}>
              Cancelar
            </CButton>
            <CButton className='btn-next-footer' >Aceptar</CButton> */}
          </CModalFooter>
        </CModalContent>
      </CModal>
    </>
  )
}

export default List
