import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
// import { ShowLogoProvider } from '../ShowLogoContext'; // Importa el contexto
import '../scss/style.scss'
import {
  CAvatar,
  CCloseButton,
  CImage,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarToggler,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
  CButton,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
} from '@coreui/icons'

import { AppSidebarNav } from './AppSidebarNav'

// import { logo } from '../assets/brand/logo'
import  logo  from '../assets/images/logoacya-white.png'
import  logoAcya  from '../assets/images/logoa.png'
import  logoCognitive  from '../assets/images/logo-cognitive.png'
import  logoCog  from '../assets/images/logo-cog.png'
// import  Avatar1  from '../assets/images/avatars/1.jpg'
import  Avatar1  from '../assets/images/logoacya552.png'
// logoacya.PNG
import { sygnet } from '../assets/brand/sygnet'

// sidebar nav config
import navigation from '../_nav'
import { useState } from 'react'

function logout() {

  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('email');
  localStorage.removeItem('total');
  localStorage.removeItem('id');
  window.location = '/';

}

const AppSidebar = ({ setProfile }) => {
  const dispatch = useDispatch()
  const narrow = useSelector((state) => state.sidebarnarrow)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [username, setUsername] = useState(localStorage.getItem('name'));
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [showLogo, setShowLogo] = useState(!narrow);

  // Función para obtener datos del servidor
  function truncateText(text, maxLength, truncationText = "...") {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + truncationText;
    }
    return text;
  }


  return (
    <CSidebar
      className="border-end sidebar-bg-orange"
      colorScheme="dark"
      position="fixed"
      narrow={narrow}
      visible={sidebarShow}
    >
      <CSidebarFooter className="border-top d-none d-lg-flex sidebar-icono-recoger">
        <CSidebarToggler className='sidebar-toggler-icon'
          onClick={() => { dispatch({ type: 'set', sidebarnarrow: !narrow, }); 
          setShowLogo(!showLogo);
          localStorage.setItem('showLogo', !showLogo);
          setProfile(!showLogo ? 'false' : 'true');
        }}

        />
      </CSidebarFooter>
      <CSidebarHeader className="">
        <CSidebarBrand to="/">
        {showLogo ? (
        <img customClassName="sidebar-brand-full" src={logo} alt="Mi logo" height={70} />
      ) : (
        <img customClassName="sidebar-brand-full" src={logoAcya} alt="Mi logo" height={45} />
      )}

      {/* <CIcon customClassName="sidebar-brand-narrow" icon={sygnet} height={32} /> */}
        </CSidebarBrand>
        <CCloseButton
          className="d-lg-none"
          dark
          onClick={() => dispatch({ type: 'set', sidebarShow: false })}
        />
      </CSidebarHeader>
      <AppSidebarNav items={navigation} />
      {/* <CSidebarFooter className="border-top d-none d-lg-flex">
        Logout
        <CSidebarToggler
          // onClick={logout}
          onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
        />
      </CSidebarFooter> */}
    
    <CSidebarFooter className="d-none d-lg-flex">
        
    <CDropdown variant="btn-group" direction="dropup">
    {/* <CButton color="secondary" >Small split button</CButton> */}
    <div className="sidebar-footer-content-all">
    {showLogo ? (
      <>
      <div className="sidebar-footer-content">
      <div className="">
        <CAvatar src={Avatar1} />
      </div>
      <div className="">
      <div className="sidebar-footer-name" title={truncateText(username, 10) === username ? '' : username}>
        {/* <strong>Nombre Apellido</strong> */}
        <strong>{truncateText(username, 18)}</strong>
      </div>
      <div className="sidebar-footer-email" title={truncateText(email, 10) === email ? '' : email}>
        {/* correo@ejemplo.com */}
        {truncateText(email, 20)}
      </div>
      </div>
    </div>
    </>
    ) : (
      <div className="sidebar-footer-left">
        <CAvatar src={Avatar1} />
      </div>
    )}

    
  </div>
  
    {showLogo ? (
      <>
      <CDropdownToggle style={{'--cui-btn-border-width': '0px'}} />
  <CDropdownMenu>
      <CDropdownItem href="#"><div className="sidebar-footer-content-all">
      <div className="sidebar-footer-content">
      <div className="">
        <CAvatar src={Avatar1} />
      </div>
      <div className="">
      <div className="sidebar-footer-name">
        {/* <strong>Nombre Apellido</strong> */}
        <strong>{username}</strong>
      </div>
      <div className="sidebar-footer-email">
        {/* correo@ejemplo.com */}
        {email}
      </div>
      </div>
    </div>
    </div></CDropdownItem>
      <CDropdownDivider/>
      <CDropdownItem href="/profile/user">Ver perfil usuario</CDropdownItem>
      <CDropdownItem className="cursor-pointer" onClick={logout}>Cerrar sesión</CDropdownItem>
      
    </CDropdownMenu>
  
    </>
    ) : (
      <div className="sidebar-footer-left">
     
      </div>
    )}

    
</CDropdown>
      </CSidebarFooter>
        <CSidebarFooter className="border-top d-none d-lg-flex">
        
      
        {showLogo ? (
        <img customClassName="sidebar-brand-narrow" src={logoCognitive} alt="Mi logo" height={45} /> 
      ) : (
        <img customClassName="sidebar-brand-full" src={logoCog} alt="Mi logo" height={30} />
      )}
      </CSidebarFooter>
    </CSidebar>
  )
}

AppSidebar.propTypes = {
  setProfile: PropTypes.func.isRequired

}

export default React.memo(AppSidebar)
