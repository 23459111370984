import React, { useState } from 'react';
import Header from '../Header/Header';
import AppSidebar from '../AppSidebar';
// import Widgets from '../widgets/Widgets';
import MyMapComponent from '../Map/MyMapComponent'; // Revisa el nombre del componente

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CListGroup,
  CListGroupItem,
  CCol,
  CRow,
} from '@coreui/react'

function Home() {
  const [profile, setProfile] = useState(localStorage.getItem('name') || '');

  return (
    <div>
      <CRow className='image-container'>
       <CCol sm={3}>
      
      <CCard style={{ width: '18rem' }}>
        <CCardImage orientation="top" src='https://cdn.pixabay.com/photo/2019/06/12/02/47/highway-4268179_1280.jpg' />
        <CCardBody>
          {/* <CCardTitle>Generate Project</CCardTitle> */}
          {/* <CCardText>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </CCardText> */}
          <CButton className='btn-primary-orange' href="/new-project">Generate Project
          </CButton>
        </CCardBody>
      </CCard>
      
      </CCol>
      
      <CCol sm={3}>
      
      <CCard style={{ width: '18rem' }}>
        <CCardImage orientation="top" src='https://cdn.pixabay.com/photo/2019/06/12/02/47/highway-4268179_1280.jpg' />
        <CCardBody>
          {/* <CCardTitle>Project Gallery</CCardTitle> */}
          {/* <CCardText>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </CCardText> */}
          <CButton className='btn-primary-orange' href="/inventory">Project View
          </CButton>
        </CCardBody>
      </CCard>
      
      </CCol>
      </CRow>
    </div>
  );
}

export default Home;